<template>
  <section v-loading="loading" :element-loading-text="loadingText">
    <div class="view-navbar">
      <div class="left-block view-navbar">
        <el-button @click="goBackConfirm" size="mini">
          <i class="el-icon-back"></i>
        </el-button>
        <h3 style="margin-top: 6px" class="ml-1">Entities view</h3>
      </div>
      <div class="right-block">
        <el-button type="primary" class="scaling-button" @click="saveViewData">
          <i class="el-icon-check"></i>
          {{ entityViewDataId ? "Update" : "Save" }}
        </el-button>
      </div>
    </div>
    <div class="view-body mt-1">
      <el-tabs
        type="border-card"
        v-model="activetab"
        @tab-click="getSelectedEntityInto"
      >
        <el-tab-pane label="Basic details" name="basic_details">
          <div>
            <div class="d-flex">
              <label style="width: 100px; margin-top: 8px">View name</label>
              <el-input
                v-model="payload.name"
                placeholder="Enter name"
                class="input-text"
              >
              </el-input>
            </div>
            <div class="d-flex mt-1">
              <label style="width: 100px; margin-top: 8px">Base entity</label>
              <el-select
                placeholder="Select entity"
                v-model="payload.entity_id"
                class="input-text"
                @change="updateAndLoadMore"
                remote
                :remote-method="searchEntities"
                filterable
                loading-text="Loading..."
                :loading="entityOptionsLoading"
                clearable
              >
                <el-option
                  v-for="entity in allEntities"
                  :key="'entity' + entity._id"
                  :value="entity._id"
                  :label="entity.name"
                >
                </el-option>
                <el-option
                  value="LOADMORE"
                  label="Load more..."
                  v-if="hasMoreEntities && !isEntitySearching"
                >
                </el-option>
              </el-select>
            </div>
            <div class="d-flex mt-1">
              <label style="width: 100px; margin-top: 8px">Select Filter</label>
              <el-select
                placeholder="Select filter"
                v-model="payload.filter"
                class="input-text"
                filterable
                loading-text="Loading..."
                :loading="entityOptionsLoading"
                clearable
              >
                <el-option
                  v-for="filter in baseEntityFilters"
                  :key="'filter' + filter._id"
                  :value="filter._id"
                  :label="filter.filter_name"
                >
                </el-option>
              </el-select>
            </div>
            <div class="d-flex mt-1">
              <label style="width: 100px; margin-top: 8px"
                >Other entities</label
              >
              <el-select
                placeholder="Select other entities"
                v-model="selectedOtherEntities"
                class="input-text"
                filterable
                clearable
                multiple
                collapse-tags
                loading-text="Loading..."
                :loading="otherEntitiesLoading"
                @remove-tag="checkselectedEntities"
              >
                <el-option-group label="As Fields">
                  <el-option
                    v-for="field in otherEntities"
                    :key="'field' + field.key"
                    :value="field.key"
                    :label="field.label"
                    :disabled="checkEntitySelected(field.key)"
                  >
                  </el-option>
                </el-option-group>
                <el-option-group label="As Relationships">
                  <el-option
                    v-for="(relationship, index) in relationshipsData"
                    :key="index"
                    :value="relationship.relationship_id + '@relationship'"
                    :label="relationship.relationship_title"
                    :disabled="
                      checkEntitySelected(
                        relationship.relationship_id + '@relationship'
                      )
                    "
                  >
                  </el-option>
                </el-option-group>
                <el-option-group label="As Nested Relationships">
                  <el-option
                    v-for="(nested, index) in nestedRelationshipsData"
                    :key="index"
                    :value="
                      nested.relationship_id +
                      '@nested-' +
                      nested.parent_relationship_details.relationship_id
                    "
                    :label="nested.relationship_title"
                  ></el-option>
                </el-option-group>
              </el-select>
            </div>
            <div class="d-flex mt-1">
              <label style="width: 100px; margin-top: 8px">Printing</label>
              <el-select
                placeholder="Select Document for Export PDF"
                v-model="payload.printing_document"
                class="input-text"
                filterable
                clearable
                loading-text="Loading..."
              >
                <el-option
                  v-for="(doc, i) in allDocumentTemplates"
                  :key="i + '_document'"
                  :value="doc.configurable_document_id"
                  :label="doc.title"
                >
                </el-option>
              </el-select>
            </div>
            <div class="d-flex mt-1" v-if="payload.printing_document">
              <label style="width: 100px; margin-top: 8px"
                >Send report to</label
              >
              <el-select
                placeholder="Select filter entity to send PDF"
                v-model="payload.send_attachment_to"
                class="input-text"
                filterable
                clearable
                loading-text="Loading..."
                @change="fetchAllEmailTemplates"
              >
                <el-option
                  v-for="(doc, i) in getEntityTypeColoumns"
                  :key="i + '_coloumn'"
                  :value="doc.key"
                  :label="doc.label"
                >
                </el-option>
              </el-select>
              <el-select
                v-if="payload.send_attachment_to"
                placeholder="Select email template for that entity"
                v-model="payload.attachment_email_template"
                class="input-text ml-1"
                filterable
                clearable
                loading-text="Loading..."
                :loading="emailTemplatesLoading"
              >
                <el-option
                  v-for="(doc, i) in allEmailTemplates"
                  :key="i + '_email_template'"
                  :value="doc._id"
                  :label="doc.name"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Filters" name="filters">
          <div>
            <div class="d-flex">
              <div style="width: 325px; margin-top: 8px">
                <label>Filters close name</label>
                <el-input
                  v-model="payload.filter_close"
                  placeholder="Enter name"
                  class="input-text"
                >
                </el-input>
              </div>
              <div style="width: 350px; margin-top: 8px">
                <label>Filters open name</label>
                <el-input
                  v-model="payload.filter_open"
                  placeholder="Enter name"
                  class="input-text"
                >
                </el-input>
              </div>
            </div>
            <div class="d-flex filter-table">
              <table>
                <tr>
                  <th>Label</th>
                  <th>Type</th>
                  <th>Linked to main entity</th>
                  <th>Linked to Application user</th>
                  <th>Is Primary Filter</th>
                  <th>
                    <el-button
                      size="mini"
                      icon="el-icon-plus"
                      @click="openFilterModal(-1, payload.filter_fields)"
                    ></el-button>
                  </th>
                </tr>
                <tr
                  v-for="(filter, i) in payload.filter_fields"
                  :key="'filter_' + i"
                >
                  <td>{{ filter.label }}</td>
                  <td>
                    <div v-if="filter.type == 'DATE' && filter.filter_type">
                      {{ capitalizeFirstLetter(filter.type) }} -
                      {{ capitalizeFirstLetter(filter.filter_type) }}
                    </div>
                    <div v-else>{{ capitalizeFirstLetter(filter.type) }}</div>
                  </td>
                  <td>{{ filter.main_entity_field ? "Yes" : "No" }}</td>
                  <td>
                    {{
                      ["ENTITY", "RELATION"].includes(filter.type)
                        ? filter.isApplicationUser
                          ? "Yes"
                          : "No"
                        : "-"
                    }}
                  </td>
                  <td>
                    {{ filter && filter.primary_filter ? "Yes" : "No" }}
                  </td>
                  <td>
                    <el-button
                      size="mini"
                      icon="el-icon-edit"
                      @click="openFilterModal(i, payload.filter_fields)"
                    ></el-button>
                    <el-button
                      size="mini"
                      icon="el-icon-minus"
                      @click="removeFilterField(i, payload.filter_fields)"
                    ></el-button>
                  </td>
                </tr>
              </table>
            </div>
            <div
              class="d-flex"
              v-if="payload.filter_fields && payload.filter_fields.length"
            >
              <div style="width: 325px; margin-top: 8px">
                <el-checkbox v-model="payload.include_filters_in_export"
                  >Include filters in export</el-checkbox
                >
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Columns" name="columns">
          <div class="d-flex">
            <el-checkbox v-model="payload.add_serial_number"
              >Add serial number</el-checkbox
            >
          </div>
          <div class="d-flex filter-table">
            <table>
              <tr>
                <th>Label</th>
                <th>Field type</th>
                <th>Linked to main entity</th>
                <th>Is hidden</th>
                <th>Is formula</th>
                <th>
                  <el-button
                    size="mini"
                    icon="el-icon-plus"
                    @click="openFilterModal(-1, payload.main_columns)"
                  ></el-button>
                </th>
              </tr>
              <tr
                v-for="(column, i) in payload.main_columns"
                :key="'column_' + i"
              >
                <td>{{ column.label }}</td>
                <td>
                  <div>{{ capitalizeFirstLetter(column.field_type) }}</div>
                </td>
                <td>{{ column.main_entity_field ? "Yes" : "No" }}</td>
                <td>{{ column.is_hide_column ? "Yes" : "No" }}</td>
                <td>
                  {{ column && column.field_type == "FORMULA" ? "Yes" : "No" }}
                </td>
                <td>
                  <el-button
                    size="mini"
                    icon="el-icon-edit"
                    @click="openFilterModal(i, payload.main_columns)"
                  ></el-button>
                  <el-button
                    size="mini"
                    icon="el-icon-minus"
                    @click="removeFilterField(i, payload.main_columns)"
                  ></el-button>
                </td>
              </tr>
            </table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Aggregations" name="aggregations">
          <div class="d-flex filter-table">
            <table>
              <tr>
                <th>Label</th>
                <th>Field type</th>
                <th>Selected fields</th>
                <th>
                  <el-button
                    size="mini"
                    icon="el-icon-plus"
                    @click="addAggregation(-1)"
                  >
                  </el-button>
                </th>
              </tr>
              <tr v-for="(column, i) in payload.aggregations" :key="i">
                <td>{{ column.label }}</td>
                <td>{{ column.type }}</td>
                <td>
                  {{
                    column.type == "Display"
                      ? getAggregationFieldLabel(column.displayField)
                      : getAggregationFormulaLabel(column.selected_fields)
                  }}
                </td>
                <td>
                  <el-button
                    size="mini"
                    icon="el-icon-edit"
                    @click="addAggregation(i)"
                  ></el-button>
                  <el-button
                    size="mini"
                    icon="el-icon-minus"
                    @click="removeFilterField(i, payload.aggregations)"
                  ></el-button>
                </td>
              </tr>
            </table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Rules" name="rules">
          <div>
            <template-form-rules-widget
              :allFields="fieldsFromColumns"
              :templateRules="payload.rules"
              :fromEntityviews="true"
              @form-rule-added="updateFormRules"
              @form-rule-delete="deleteFormRules"
              class="popUp-content"
            >
            </template-form-rules-widget>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- adding new filter dialog -->

    <dialog-component
      :visible="addNewFilterModal"
      top="5vh"
      :containerWidth="getIsMobile ? '100%' : '50%'"
      :title="
        selectedFilterIndex > -1 ? 'Edit ' + activetab : 'Add New ' + activetab
      "
      @before-close="resetAddingFilterModal"
    >
      <div class="mt-1 mb-1 ml-1 mr-2">
        <template v-loading="filterLoading">
          <div v-if="activetab == 'filters'">
            <div class="mt-15">
              <div class="view-navbar">
                <label>Filter label</label>
                <el-checkbox v-model="newFiltetObject.required"
                class="mr-1 mb-05"
                  >Required</el-checkbox
                >
              </div>
              <el-input
                v-model="newFiltetObject.label"
                placeholder="Enter filter label"
              ></el-input>
            </div>
            <div class="margin-top-10">
              <div class="view-navbar">
                <el-checkbox
                  @change="setCurrentEntityDetails"
                  v-model="newFiltetObject.main_entity"
                  >Is main entity?</el-checkbox
                >
                <el-checkbox
                  v-if="newFiltetObject.main_entity"
                  v-model="newFiltetObject.multiple"
                  class="mr-1"
                  >Multiple</el-checkbox
                >
              </div>
            </div>
            <div class="margin-top-10" v-if="!newFiltetObject.main_entity">
              <el-radio-group
                v-model="newFiltetObject.main_entity_field"
                @change="updateEntityId"
              >
                <el-radio :label="true">Main entity fields</el-radio>
                <el-radio :label="false">Other entity fields</el-radio>
              </el-radio-group>
              <div v-if="newFiltetObject.main_entity_field">
                <div class="view-navbar">
                  <label>Field</label>
                  <el-checkbox
                    v-if="selectionFields.indexOf(newFiltetObject.type) !== -1"
                    v-model="newFiltetObject.multiple"
                    class="mr-1"
                    >Multiple</el-checkbox
                  >
                </div>
                <el-select
                  placeholder="Select field"
                  v-model="newFiltetObject.key"
                  class="input-text"
                  filterable
                  clearable
                  style="width: 100%"
                  @change="(changedValue) => setFilterDetails(changedValue)"
                >
                  <el-option
                    v-for="field in eliminateDateFilters(
                      getSelectedEntityFields(
                        ['DATE', 'DATE_TIME', ...selectionFields],
                        newFiltetObject.linked_entity_id
                      )
                    )"
                    :key="'add_filter' + field.key"
                    :value="
                      newFiltetObject.filter_type == 'PICKER_OPTIONS'
                        ? field.key + '/picker'
                        :  field.key
                    "
                    :label="field.label"
                  >
                  </el-option>
                  <el-option
                    v-for="(filter, index) in getRelatedNestedRelatedFilters"
                    :key="index"
                    :label="getSelfFilterLabel(filter)"
                    :value="filter"
                  >
                  </el-option>
                </el-select>
                <div class="margin-top-10">
                  <label v-if="newFiltetObject.type == 'DATE'"
                    >Picker options</label
                  >
                  <el-select
                    v-if="newFiltetObject.type == 'DATE'"
                    v-model="newFiltetObject.filter_type"
                    placeholder="Picker options"
                    style="width: 100%"
                    @change="(changedValue) => setDateFilter(changedValue)"
                  >
                    <el-option
                      value="PICKER_OPTIONS"
                      label="Picker options"
                      :disabled="checkPickerOption('PICKER_OPTIONS')"
                    >
                    </el-option>
                    <el-option
                      value="DATE_RANGE"
                      label="Date range"
                      :disabled="checkPickerOption('DATE_RANGE')"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div v-else>
                <div>
                  <label>Field</label>
                  <el-select
                    placeholder="Select other fields"
                    v-model="tempOtherFilterField"
                    class="input-text"
                    filterable
                    clearable
                    style="width: 100%"
                    @change="addToOtherEntityFilters"
                  >
                    <el-option
                      v-for="(field, i) in getOtherEntityFields([
                        'DATE',
                        ...selectionFields,
                      ])"
                      :key="'other_filter' + field.key + '_' + i"
                      :value="
                        newFiltetObject.filter_type == 'PICKER_OPTIONS'
                          ? field.entity_id + '#' + field.key + '/picker'
                          : field.entity_id + '#' + field.key
                      "
                      :label="field.label"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div class="margin-top-10">
                  <label v-if="newFiltetObject.type == 'DATE'"
                    >Picker options</label
                  >
                  <el-select
                    v-if="newFiltetObject.type == 'DATE'"
                    v-model="newFiltetObject.filter_type"
                    placeholder="Picker options"
                    style="width: 100%"
                    @change="(changedValue) => setDateFilter(changedValue)"
                  >
                    <el-option value="PICKER_OPTIONS" label="Picker options">
                    </el-option>
                    <el-option value="DATE_RANGE" label="Date range">
                    </el-option>
                  </el-select>
                </div>
                <div class="margin-top-10">
                  <label>Related fields</label>
                  <el-select
                    placeholder="Select other fields"
                    v-model="newFiltetObject.linked_entity_ids"
                    class="input-text mb-10"
                    filterable
                    clearable
                    multiple
                    style="width: 100%"
                    @change="addToOtherEntityFilters"
                  >
                    <el-option
                      v-for="field in getRelatedOtherEntityFields"
                      :key="'other_related_filter' + field.key"
                      :value="field.entity_id + '#' + field.key"
                      :label="field.label"
                      :disabled="
                        tempOtherFilterField ==
                        field.entity_id + '#' + field.key
                      "
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <el-checkbox
                v-if="['ENTITY', 'RELATION'].includes(newFiltetObject.type)"
                class="mt-1"
                v-model="newFiltetObject.isApplicationUser"
                >Link to Application User</el-checkbox
              >
              <el-tooltip
                v-if="newFiltetObject.type == 'ENTITY'"
                content="This filter will be autofilled with the logged-in user at the application user side."
                ><i class="el-icon-info ml-1"></i
              ></el-tooltip>
              <br />
              <el-checkbox
                v-model="newFiltetObject.primary_filter"
                v-if="showPrimaryFilter(newFiltetObject)"
              >
                Primary Filter
              </el-checkbox>
              <label v-if="newFiltetObject.primary_filter"
                >Header Template<span class="error">*</span></label
              ><br />
              <el-select
                v-if="newFiltetObject.primary_filter"
                v-model="payload.printing_template"
                filterable
                clearable
              >
                <el-option
                  v-for="(temp, index) in allFormTemplates"
                  :key="index"
                  :value="temp._id"
                  :label="temp.name"
                >
                </el-option>
              </el-select>
            </div>
            <div
              class="margin-top-10"
              v-if="newFiltetObject && newFiltetObject.type == 'ENTITY'"
            >
              <label>Filters</label>
              <EntityFilters
                :field="newFiltetObject"
                :fieldsData="fieldsData"
              ></EntityFilters>
            </div>
          </div>
          <div v-else-if="activetab == 'aggregations'">
            <label>Label</label><br />
            <el-input
              placeholder="Enter label"
              v-model="newAggregationObject.label"
            ></el-input
            ><br /><br />
            <label>Select Type</label><br />
            <el-radio-group v-model="newAggregationObject.type">
              <el-radio label="Display"></el-radio>
              <el-radio label="Formula"></el-radio> </el-radio-group
            ><br /><br />
            <label v-if="newAggregationObject.type == 'Display'"
              >Select Field</label
            ><br />
            <el-select
              v-if="newAggregationObject.type == 'Display'"
              v-model="newAggregationObject.displayField"
              filterable
              clearable
            >
              <el-option
                v-for="(item, i) of getNumberColumnsFieldsAggregation"
                :key="item.key + '_' + i"
                :label="item.label"
                :value="
                  item.key.split('#')[0] +
                  '#' +
                  item.linked_entity_id +
                  '#' +
                  item.template_id +
                  '#' +
                  item.key.split('#')[1] +
                  (item.filter != '' ? '@' + item.filter : '')
                "
              ></el-option>
            </el-select>
            <div class="formula-dialog" ref="form" v-else>
              <div class="artboard" v-if="!refreshFields">
                <div
                  v-for="(item, index) of newAggregationObject.selected_fields"
                  :key="index"
                >
                  <el-button
                    v-if="showFieldButton(item.type)"
                    size="medium"
                    class="btn-variable"
                    @click="swapFields(index)"
                    :class="getActiveClass(index)"
                  >
                    <span v-if="item.type == 'OPERATOR'">{{ item.value }}</span>

                    <span v-if="item.type == 'FIELD'">{{ item.label }}</span>
                    <span v-if="item.type == 'PARENTHESES'">{{
                      item.value
                    }}</span>
                    <span v-if="item.type == 'FORMULA'">{{
                      item.formula
                    }}</span>
                    <span v-if="item.type == 'NUMBER'">{{ item.value }}</span>
                    <span v-if="item.type == 'CURRENCY'">{{
                      item.currency
                    }}</span>
                    <span v-if="item.type == 'DATE'">{{ item.date }}</span>
                    <span v-if="item.type == 'TIME'">{{ item.time }}</span>
                    <span v-if="item.type == 'DATE_TIME_RANGE'">{{
                      item.date_time_range
                    }}</span>
                    <span v-if="item.type == 'DATE_RANGE'">{{
                      item.date_range
                    }}</span>
                    <span v-if="item.type == 'LIST'">{{ item.list }}</span>
                    <span v-if="item.type == 'GLOBAL_VARIABLE'">{{
                      item.field_name
                    }}</span>
                    <span>
                      <img
                        src="@/assets/img/icons/transfer.svg"
                        alt="innerchange"
                      />
                    </span>
                  </el-button>
                  <el-button
                    v-if="item.type == 'OPERATOR'"
                    :type="getOperationType(item.value)"
                    size="mini"
                    class="btn-operation"
                    @click="swapFields(index)"
                    circle
                    :class="getActiveClass(index)"
                  >
                    <img
                      v-if="item.value == '+'"
                      src="@/assets/img/calculator/plus.svg"
                      alt="brackets-left"
                    />
                    <img
                      v-if="item.value == '-'"
                      src="@/assets/img/calculator/minus.svg"
                      alt="brackets-left"
                    />
                    <img
                      v-if="item.value == '*'"
                      src="@/assets/img/calculator/close.svg"
                      alt="brackets-left"
                    />
                    <img
                      v-if="item.value == '/'"
                      src="@/assets/img/calculator/divider.svg"
                      alt="brackets-left"
                    />
                  </el-button>
                  <el-button
                    v-if="item.type == 'PARENTHESES' && item.value == '('"
                    type="text"
                    effect="dark"
                    class="text-button"
                    >(</el-button
                  >
                  <el-button
                    v-if="item.type == 'PARENTHESES' && item.value == ')'"
                    type="text"
                    effect="dark"
                    class="text-button"
                    >)</el-button
                  >
                  <!-- <el-button
                    v-if="item.type == 'CURRENT_DATE'"
                    size="medium"
                    class="btn-variable"
                    @click="swapFields(index)"
                    :class="getActiveClass(index)"
                    >CURRENT DATE</el-button
                  > -->
                </div>
              </div>
              <div class="variables-fields mt-1">
                <div class="mr-1">
                  <label>Fields</label><br />
                  <el-select
                    v-model="showSelectField"
                    :disabled="checkAggregationDisabled('field')"
                    @change="onSelectAggregation"
                    placeholder="Select Fields"
                    filterable
                  >
                    <el-option
                      v-for="(item, i) of getNumberColumnsFieldsAggregation"
                      :key="item.key + '_' + i"
                      :label="item.label"
                      :value="
                        item.key +
                        (item && item.filter && item.filter != ''
                          ? '@' + item.filter
                          : '')
                      "
                    ></el-option> </el-select
                  ><br />
                  <label>Result type</label>
                  <el-select v-model="newAggregationObject.result_type">
                    <el-option
                      label="PERCENTAGE"
                      value="PERCENTAGE"
                    ></el-option>
                    <el-option
                      v-for="(resultType, index) in getResultTypes"
                      :key="index"
                      :value="resultType.result_type"
                      :label="resultType.result_type"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div>
                  <label>Numbers</label>
                  <el-input
                    v-model="manualNumber"
                    placeholder="Enter Number"
                    :disabled="checkAggregationDisabled('number')"
                  >
                    <el-button
                      slot="append"
                      type="primary"
                      icon="el-icon-plus"
                      @click="addNumber(true)"
                      :disabled="manualNumber < 0"
                    ></el-button>
                  </el-input>
                </div>
                <!-- <div>
                <el-button
                  slot="append"
                  type="primary"
                  icon="el-icon-plus"
                  style="margin-top: 20px; margin-left: 5px"
                  @click="addCurrentDate"
                  >Current date</el-button
                >
              </div> -->
                <div class="operaters">
                  <el-button-group class="brackets-btn-group">
                    <el-button
                      type="success"
                      @click="onSelectOperator('+', true)"
                      class="btn-operation"
                      size="mini"
                      :disabled="checkAggregationDisabled('operator')"
                    >
                      <img
                        src="@/assets/img/calculator/plus.svg"
                        alt="brackets-left"
                      />
                    </el-button>
                    <el-button
                      type="primary"
                      @click="onSelectOperator('-', true)"
                      class="btn-operation"
                      size="mini"
                      :disabled="checkAggregationDisabled('operator')"
                    >
                      <img
                        src="@/assets/img/calculator/minus.svg"
                        alt="brackets-left"
                      />
                    </el-button>
                    <el-button
                      type="info"
                      @click="onSelectOperator('*', true)"
                      class="btn-operation"
                      size="mini"
                      :disabled="checkAggregationDisabled('operator')"
                    >
                      <img
                        src="@/assets/img/calculator/close.svg"
                        alt="brackets-left"
                      />
                    </el-button>
                    <el-button
                      type="warning"
                      @click="onSelectOperator('/', true)"
                      class="btn-operation"
                      size="mini"
                      :disabled="checkAggregationDisabled('operator')"
                    >
                      <img
                        src="@/assets/img/calculator/divider.svg"
                        alt="brackets-left"
                      />
                    </el-button>
                  </el-button-group>
                  <el-button-group class="brackets-btn-group">
                    <el-button
                      type="info"
                      @click="onSelectParentheses('(', true)"
                      class="btn-operation"
                      :disabled="checkAggregationDisabled('parentheses', '(')"
                      >(</el-button
                    >
                    <el-button
                      type="info"
                      @click="onSelectParentheses(')', true)"
                      class="btn-operation"
                      :disabled="checkAggregationDisabled('parentheses', ')')"
                      >)</el-button
                    >

                    <el-button
                      type="danger"
                      @click="popAggregationField()"
                      class="btn-operation"
                      :disabled="
                        newAggregationObject &&
                        newAggregationObject.selected_fields &&
                        newAggregationObject.selected_fields.length < 1
                      "
                    >
                      <i class="el-icon-delete"></i>
                    </el-button>
                  </el-button-group>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div>
              <div class="view-navbar">
                <label class="mb-05">Column label</label>
                <div>
                  <el-checkbox v-model="newFiltetObject.ignore_if_empty"
                    >Ignore if empty</el-checkbox
                  >
                  <el-checkbox v-model="newFiltetObject.is_hide_column"
                    >Hidden field</el-checkbox
                  >
                </div>
              </div>
              <el-input
                v-model="newFiltetObject.label"
                placeholder="Enter Column Label"
              ></el-input>
            </div>
            <div class="mt-2">
              <div class="view-navbar">
                <el-radio-group
                  v-model="newFiltetObject.main_entity_field"
                  @change="updateEntityId"
                >
                  <el-radio :label="true">Main entity fields</el-radio>
                  <el-radio :label="false">Other entity fields</el-radio>
                </el-radio-group>
                <div
                  v-if="
                    newFiltetObject.field_type == 'DISPLAY' &&
                    newFiltetObject.main_entity_field
                  "
                  class="d-flex-col"
                >
                  <el-checkbox v-model="newFiltetObject.allow_view_details">
                    View main entity details
                  </el-checkbox>
                </div>
                <div
                  v-if="
                    newFiltetObject.field_type == 'DISPLAY' &&
                    newFiltetObject.linked_entity_id &&
                    newFiltetObject.linked_entity_id !== payload.entity_id
                  "
                  class="d-flex-col"
                >
                  <el-checkbox v-model="newFiltetObject.allow_view_details">
                    Allow to view details
                  </el-checkbox>
                  <el-select
                    placeholder="field type"
                    v-model="newFiltetObject.detailed_entity_view_id"
                    size="mini"
                    v-if="newFiltetObject.allow_view_details"
                  >
                    <el-option
                      v-for="view in allEntityViews"
                      :key="view._id"
                      :value="view._id"
                      :label="view.name"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="mt-1 d-flex" style="">
                <div>
                  <div>
                    <label>Field type</label>
                  </div>
                  <el-select
                    placeholder="field type"
                    v-model="newFiltetObject.field_type"
                    size="mini"
                  >
                    <el-option value="FILTER" label="Filter"> </el-option>
                    <el-option value="DISPLAY" label="Display"> </el-option>
                    <el-option value="FORMULA" label="Formula"> </el-option>
                    <el-option value="SUM" label="Sum"> </el-option>
                    <el-option value="AVG" label="Avg"> </el-option>
                    <el-option value="COUNT" label="Count"></el-option>
                  </el-select>
                </div>
                <div
                  v-if="
                    newFiltetObject.field_type &&
                    newFiltetObject.field_type !== 'FORMULA'
                  "
                  class="ml-1"
                >
                  <div>
                    <label>Linked entity</label>
                  </div>
                  <el-select
                    placeholder="Linked entity"
                    v-model="newFiltetObject.linked_entity_id"
                    filterable
                    clearable
                    size="mini"
                  >
                    <el-option
                      v-for="(entity, i) in selectedEntities"
                      :key="'other_linked_filter' + i"
                      :value="entity._id"
                      :label="entity.name"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div
                  v-if="
                    ['SUM', 'AVG', 'COUNT'].includes(
                      newFiltetObject.field_type
                    ) && newFiltetObject.linked_entity_id
                  "
                  class="ml-1"
                >
                  <label>Filter</label>
                  <el-select
                    filterable
                    clearable
                    v-model="newFiltetObject.filter"
                  >
                    <el-option
                      v-for="(filter, index) in allEntityFilters[
                        newFiltetObject.linked_entity_id
                      ]"
                      :key="index"
                      :value="filter._id"
                      :label="filter.filter_name"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div v-if="newFiltetObject.linked_entity_id" class="mr-1 ml-1">
                  <div>
                    <label>Field</label>
                  </div>
                  <el-select
                    placeholder="Field"
                    v-model="newFiltetObject.key"
                    @change="(changedValue) => setColumnDetails(changedValue)"
                    filterable
                    clearable
                    size="mini"
                  >
                    <el-option
                      v-for="field in getRelatedFiltersEntities(
                        getSelectedEntityFields(
                          [],
                          newFiltetObject.linked_entity_id
                        )
                      )"
                      :key="'add_column_' + field.key"
                      :value="field.key.includes('#') ? field.key.split('#')[1] : field.key"
                      :label="field.label"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div class="mr-1 ml-1">
                  <div>
                    <label>Default value</label>
                  </div>
                  <el-input
                    placeholder="Default value"
                    v-model="newFiltetObject.default_value"
                    size="mini"
                  >
                  </el-input>
                </div>
                <div
                  v-if="
                    (newFiltetObject.linked_entity_id && newFiltetObject.key) ||
                    newFiltetObject.field_type == 'FORMULA'
                  "
                  :class="
                    !(
                      newFiltetObject.linked_entity_id && newFiltetObject.key
                    ) && newFiltetObject.field_type == 'FORMULA'
                      ? 'ml-1'
                      : ''
                  "
                >
                  <div>
                    <label>Alignment</label>
                  </div>
                  <el-select
                    placeholder="Alignment"
                    v-model="newFiltetObject.alignment"
                  >
                    <el-option value="left" label="Left"> </el-option>
                    <el-option value="right" label="Right"> </el-option>
                    <el-option value="center" label="Center"> </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="mt-1">
              <el-checkbox v-model="newFiltetObject.include_total"
                >Include totals row if column has number value</el-checkbox
              >
              <el-checkbox v-model="newFiltetObject.include_totals_in_export"
                >Include totals in export if column has number
                value</el-checkbox
              >
            </div>
            <div
              class="margin-top-10"
              v-if="newFiltetObject.field_type == 'FORMULA'"
            >
              <div class="formula-dialog" ref="form">
                <div class="artboard" v-if="!refreshFields">
                  <div
                    v-for="(item, index) of newFiltetObject.selected_fields"
                    :key="index"
                  >
                    <el-button
                      v-if="showFieldButton(item.type)"
                      size="medium"
                      class="btn-variable"
                      @click="swapFields(index)"
                      :class="getActiveClass(index)"
                    >
                      <span v-if="item.type == 'OPERATOR'">{{
                        item.value
                      }}</span>

                      <span v-if="item.type == 'FIELD'">{{
                        getFieldLabel(item)
                      }}</span>
                      <span v-if="item.type == 'PARENTHESES'">{{
                        item.value
                      }}</span>
                      <span v-if="item.type == 'FORMULA'">{{
                        item.formula
                      }}</span>
                      <span v-if="item.type == 'NUMBER'">{{ item.value }}</span>
                      <span v-if="item.type == 'CURRENCY'">{{
                        item.currency
                      }}</span>
                      <span v-if="item.type == 'DATE'">{{ item.date }}</span>
                      <span v-if="item.type == 'TIME'">{{ item.time }}</span>
                      <span v-if="item.type == 'DATE_TIME_RANGE'">{{
                        item.date_time_range
                      }}</span>
                      <span v-if="item.type == 'DATE_RANGE'">{{
                        item.date_range
                      }}</span>
                      <span v-if="item.type == 'LIST'">{{ item.list }}</span>
                      <span v-if="item.type == 'GLOBAL_VARIABLE'">{{
                        item.field_name
                      }}</span>
                      <span>
                        <img
                          src="@/assets/img/icons/transfer.svg"
                          alt="innerchange"
                        />
                      </span>
                    </el-button>
                    <el-button
                      v-if="item.type == 'OPERATOR'"
                      :type="getOperationType(item.value)"
                      size="mini"
                      class="btn-operation"
                      @click="swapFields(index)"
                      circle
                      :class="getActiveClass(index)"
                    >
                      <img
                        v-if="item.value == '+'"
                        src="@/assets/img/calculator/plus.svg"
                        alt="brackets-left"
                      />
                      <img
                        v-if="item.value == '-'"
                        src="@/assets/img/calculator/minus.svg"
                        alt="brackets-left"
                      />
                      <img
                        v-if="item.value == '*'"
                        src="@/assets/img/calculator/close.svg"
                        alt="brackets-left"
                      />
                      <img
                        v-if="item.value == '/'"
                        src="@/assets/img/calculator/divider.svg"
                        alt="brackets-left"
                      />
                    </el-button>
                    <el-button
                      v-if="item.type == 'PARENTHESES' && item.value == '('"
                      type="text"
                      effect="dark"
                      class="text-button"
                      >(</el-button
                    >
                    <el-button
                      v-if="item.type == 'PARENTHESES' && item.value == ')'"
                      type="text"
                      effect="dark"
                      class="text-button"
                      >)</el-button
                    >
                    <el-button
                      v-if="item.type == 'CURRENT_DATE'"
                      size="medium"
                      class="btn-variable"
                      @click="swapFields(index)"
                      :class="getActiveClass(index)"
                      >CURRENT DATE</el-button
                    >
                  </div>
                </div>
                <div class="variables-fields mt-1">
                  <div class="mr-1">
                    <label>Fields</label><br />
                    <el-select
                      v-model="showSelectField"
                      :disabled="checkDisabled('field')"
                      @change="onSelectField"
                      placeholder="Select Fields"
                    >
                      <el-option
                        v-for="(item, i) of getNumberColumnsFields"
                        :key="item.key + '_' + i"
                        :label="item.label"
                        :value-key="'key'"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </div>
                  <div>
                    <label>Numbers</label>
                    <el-input
                      v-model="manualNumber"
                      placeholder="Enter Number"
                      :disabled="checkDisabled('number')"
                    >
                      <el-button
                        slot="append"
                        type="primary"
                        icon="el-icon-plus"
                        @click="addNumber(false)"
                        :disabled="manualNumber < 0"
                      ></el-button>
                    </el-input>
                  </div>
                  <div>
                    <el-button
                      slot="append"
                      type="primary"
                      icon="el-icon-plus"
                      style="margin-top: 20px; margin-left: 5px"
                      @click="addCurrentDate"
                      >Current date</el-button
                    >
                  </div>
                  <div class="operaters">
                    <el-button-group class="brackets-btn-group">
                      <el-button
                        type="success"
                        @click="onSelectOperator('+')"
                        class="btn-operation"
                        size="mini"
                        :disabled="checkDisabled('operator')"
                      >
                        <img
                          src="@/assets/img/calculator/plus.svg"
                          alt="brackets-left"
                        />
                      </el-button>
                      <el-button
                        type="primary"
                        @click="onSelectOperator('-')"
                        class="btn-operation"
                        size="mini"
                        :disabled="checkDisabled('operator')"
                      >
                        <img
                          src="@/assets/img/calculator/minus.svg"
                          alt="brackets-left"
                        />
                      </el-button>
                      <el-button
                        type="info"
                        @click="onSelectOperator('*')"
                        class="btn-operation"
                        size="mini"
                        :disabled="checkDisabled('operator')"
                      >
                        <img
                          src="@/assets/img/calculator/close.svg"
                          alt="brackets-left"
                        />
                      </el-button>
                      <el-button
                        type="warning"
                        @click="onSelectOperator('/')"
                        class="btn-operation"
                        size="mini"
                        :disabled="checkDisabled('operator')"
                      >
                        <img
                          src="@/assets/img/calculator/divider.svg"
                          alt="brackets-left"
                        />
                      </el-button>
                    </el-button-group>
                    <el-button-group class="brackets-btn-group">
                      <el-button
                        type="info"
                        @click="onSelectParentheses('(', false)"
                        class="btn-operation"
                        :disabled="checkDisabled('parentheses', '(')"
                        >(</el-button
                      >
                      <el-button
                        type="info"
                        @click="onSelectParentheses(')', false)"
                        class="btn-operation"
                        :disabled="checkDisabled('parentheses', ')')"
                        >)</el-button
                      >

                      <el-button
                        type="danger"
                        @click="popField()"
                        class="btn-operation"
                        :disabled="
                          newFiltetObject &&
                          newFiltetObject.selected_fields &&
                          newFiltetObject.selected_fields.length < 1
                        "
                      >
                        <i class="el-icon-delete"></i>
                      </el-button>
                    </el-button-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetAddingFilterModal">Cancel</el-button>
        <el-button
          type="primary"
          @click="addNewFilter"
          :disabled="checkFormDisabled"
          >{{
            selectedFilterIndex > -1 ? "Update " + activetab : "Add " + activetab
          }}</el-button
        >
      </span>
    </dialog-component>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import EntityHelper from "@/mixins/EntitiesHelper";
import entityRelationshipMixin from "../../mixins/entityRelationshipMixin";
import * as mexp from "math-expression-evaluator";
import { postAPICall } from "../../helpers/httpHelper";
import { fetchEntitiesByPagination } from "@/repo/entityRepo";
import {
  fetchEntityViews,
  addSingleEntityViewToLocal,
  updateEntityViewData,
} from "@/repo/entityViewsRepo";
import { getAllCompanyTemplatesByPagination } from "@/repo/templatesRepo";
import AutomationHelper from "../../mixins/AutomationHelper";
export default {
  data() {
    return {
      loading: false,
      loadingText: "Loading...",
      payload: {
        filter_open: "Show",
        filter_close: "Hide",
        add_serial_number: true,
        include_total: true,
        include_filters_in_export: true,
        include_totals_in_export: true,
        name: "",
        entity_id: "",
        other_entities: [],
        filter_fields: [],
        main_columns: [],
        printing_document: "",
        attachment_email_template: "",
        send_attachment_to: "",
        printing_template: "",
        aggregations: [],
        filter: "",
        rules: [],
        query_type: "AND",
      },
      entityViewDataId: "",
      activetab: "basic_details",
      allEntities: [],
      hasMoreEntities: false,
      entityPage: 1,
      entityLimit: 10,
      isEntitySearching: false,
      entityOptionsLoading: false,
      entitySearchString: "",
      entityLoadMore: false,
      selectedOtherEntities: [],
      otherEntities: [],
      otherEntitiesLoading: false,
      addNewFilterModal: false,
      selectedFilterIndex: -1,
      newFiltetObject: {},
      selectedEntitiesData: {},
      selectedEntitiesDataArray: [],
      selectionFields: [
        "SELECT",
        "ENTITY",
        "ENTITY_VARIABLE",
        "MULTI_SELECT",
        "RELATION",
      ],
      tempOtherFilterField: "",
      filterLoading: false,
      showSelectField: "",
      showSelectOperator: "",
      showSelectParentheses: "",
      showSelectGlobalVariable: "",
      activeClassIndex: -1,
      manualNumber: "",
      refreshFields: false,
      allEntityViews: [],
      allDocumentTemplates: [],
      allEmailTemplates: [],
      pdfExport: false,
      newAggregationObject: {},
      relationshipsData: [],
      allEntityFilters: [],
      nestedRelationshipsData: [],
      allFormTemplates: [],
      templatesFieldsMap: {},
      baseEntityFilters: [],
      fieldsFromColumns: [],
      getEntityFieldRelatedEntities: null,
    };
  },
  components: {
    EntityFilters: () =>
      import("@/components/templates/formComponents/EntityFilters"),
    TemplateFormRulesWidget: () =>
      import("../widgets/TemplateFormRulesWidget.vue"),
  },
  mixins: [EntityHelper, entityRelationshipMixin, AutomationHelper],
  mounted() {
    this.entityViewDataId = this.$route.params.entity_view_id || null;
    this.getNecessaryInfo();
  },
  computed: {
    ...mapGetters("entities", ["getEntityDataByQuery"]),
    ...mapGetters("entityViews", [
      // "getEntityViewById",
      "getCreateEntityView",
      // "getUpdateEntityView",
      // "getAllEntityViews",
    ]),
    ...mapGetters("documents", ["getAllTemplates"]),
    ...mapGetters("companyTemplates", ["getAllCompanyTemplatesData"]),
    ...mapGetters("entityEmailTemplate", ["getAllCompanyEmailTemplatesData"]),
    ...mapGetters("navigationOpen", ["getIsMobile"]),
    selectedEntities() {
      let result = [];
      if (this.payload?.entity_id) {
        let entity = this.selectedEntitiesDataArray.find(
          (e) => e._id == this.payload?.entity_id
        );
        if (entity?._id) {
          result.push(entity);
        }
      }
      if (this.selectedOtherEntities.length) {
        this.selectedOtherEntities.forEach((en) => {
          let [id] = en.includes("@") ? en.split("@") : en.split("#");
          if (id) {
            let entity = this.selectedEntitiesDataArray.find(
              (e) => e._id == id
            );
            if (entity) {
              result.push(entity);
            }
          }
        });
      }
      return result;
    },
    getRelatedOtherEntityFields() {
      let types = ["DATE", ...this.selectionFields];
      let fields = this.getOtherEntityFields(types);
      if (this.tempOtherFilterField) {
        let selectedField = fields.find(
          (f) => f.entity_id + "#" + f.key == this.tempOtherFilterField
        );
        return fields.filter((e) => {
          if (e.type == selectedField.type) {
            if (
              (e?.entity_id !== selectedField.entity_id &&
                e.field_entity_id == selectedField.field_entity_id) ||
              (e?.entity_id == selectedField.entity_id &&
                e.field_entity_id == selectedField.field_entity_id &&
                e.key == selectedField.key)
            ) {
              return true;
            }
          }
        });
      }
      return [];
    },
    currentEntity() {
      if (
        this.newFiltetObject?.entity_id &&
        this.newFiltetObject?.type == "ENTITY" &&
        this.selectedEntitiesDataArray
      ) {
        return this.selectedEntitiesDataArray.find(
          (e) => e._id == this.newFiltetObject?.entity_id
        );
      }
      return null;
    },
    fieldsData() {
      if (this.payload?.filter_fields) {
        return this.payload.filter_fields;
      }
      return [];
    },
    checkFormDisabled() {
      if (this.newFiltetObject || this.newAggregationObject) {
        if (!this.newFiltetObject?.label && !this.newAggregationObject?.label) {
          return true;
        } else if (this.activetab == "columns") {
          if (!this.newFiltetObject?.field_type) {
            return false;
          }
          if (this.newFiltetObject?.field_type == "FORMULA") {
            return this.validate ? false : true;
          } else {
            if (
              !(
                this.newFiltetObject.main_entity_field == true ||
                this.newFiltetObject.main_entity_field == false
              )
            ) {
              return true;
            }
            if (
              !this.newFiltetObject?.linked_entity_id ||
              !this.newFiltetObject?.template_id ||
              !this.newFiltetObject?.key
            ) {
              return true;
            }
          }
          return false;
        } else if (this.activetab == "aggregations") {
          if (
            this.newAggregationObject?.type == "Display" &&
            this.newAggregationObject?.displayField == ""
          ) {
            return true;
          } else if (this.newAggregationObject?.type == "Formula") {
            this.validateAggregation ? false : true;
          }
          return false;
        } else {
          if (
            this.newFiltetObject?.primary_filter &&
            this.payload?.printing_template == ""
          ) {
            return true;
          }
          if (!this.newFiltetObject?.main_entity) {
            if (this.newFiltetObject.main_entity_field) {
              if (
                !this.newFiltetObject.key ||
                (this.newFiltetObject.type == "DATE" &&
                  !this.newFiltetObject.filter_type)
              ) {
                return true;
              }
            } else {
              if (!this.tempOtherFilterField) {
                return true;
              }
            }
            if (
              this.newFiltetObject.type == "ENTITY" &&
              this.newFiltetObject.filters &&
              this.newFiltetObject.filters.length
            ) {
              const nonConfiguredFilter = this.newFiltetObject.filters.find(
                (e) =>
                  !e.field ||
                  (e.field == "RESOURCE_MANAGEMENT" &&
                    (!e.resource_count ||
                      !e.resource_source ||
                      !e.data_source ||
                      (!e.resource_value_field && !e.resource_value) ||
                      (!e.value && !e.value_field))) ||
                  (e.field == "UNIQUE" &&
                    (!e.data_source || (!e.value && !e.value_field))) ||
                  (e.field != "UNIQUE" &&
                    e.field != "RESOURCE_MANAGEMENT" &&
                    (!e.operator ||
                      (!e.value &&
                        !e.value_field &&
                        typeof e.value !== "number" &&
                        e.data_source != "self_field" &&
                        e.data_source != "from_date" &&
                        e.operator != "exists" &&
                        e.operator != "notexists" &&
                        e.data_source != "GLOBAL_VARIABLE" &&
                        e.operator != "weekdays") ||
                      (!e.data_source &&
                        e.operator != "exists" &&
                        e.operator != "notexists" &&
                        e.operator != "between" &&
                        e.operator != "real_time" &&
                        e.operator != "weekdays")))
              );
              if (nonConfiguredFilter) {
                return true;
              }
            }
          }
        }
        return false;
      }
      return false;
    },
    validate() {
      let exp = "";
      let lastField = "";
      let err = false;
      if (
        this.newFiltetObject?.selected_fields &&
        this.newFiltetObject.selected_fields.length > 2
      ) {
        this.newFiltetObject.selected_fields.map((el) => {
          if (el.type == "OPERATOR") {
            exp += el.value + " ";
            if (lastField != "operator") {
              lastField = "operator";
            } else {
              err = true;
            }
          } else if (el.type == "PARENTHESES") {
            exp += el.value + " ";
            lastField = "";
          } else {
            exp += Math.floor(Math.random() * Math.floor(9)) + " ";
            if (lastField != "number") {
              lastField = "number";
            } else {
              err = true;
            }
          }
          return;
        });
        try {
          if (err) {
            return false;
          } else {
            mexp.eval(exp);
            return true;
          }
        } catch (e) {
          return false;
        }
      } else {
        return false;
      }
    },
    validateAggregation() {
      let exp = "";
      let lastField = "";
      let err = false;
      if (
        this.newAggregationObject?.selected_fields &&
        this.newAggregationObject.selected_fields.length > 2
      ) {
        this.newAggregationObject.selected_fields.map((el) => {
          if (el.type == "OPERATOR") {
            exp += el.value + " ";
            if (lastField != "operator") {
              lastField = "operator";
            } else {
              err = true;
            }
          } else if (el.type == "PARENTHESES") {
            exp += el.value + " ";
            lastField = "";
          } else {
            exp += Math.floor(Math.random() * Math.floor(9)) + " ";
            if (lastField != "number") {
              lastField = "number";
            } else {
              err = true;
            }
          }
          return;
        });
        try {
          if (err) {
            return false;
          } else {
            mexp.eval(exp);
            return true;
          }
        } catch (e) {
          return false;
        }
      } else {
        return false;
      }
    },
    getFormula() {
      let fields =
        this.newFiltetObject && this.newFiltetObject.selected_fields
          ? this.newFiltetObject.selected_fields
          : [];
      let formula = "";
      for (let index = 0; index < fields.length; index++) {
        if (fields[index]) {
          if (fields[index].type == "FIELD") {
            formula +=
              " " +
              (fields[index].field_name
                ? fields[index].field_name
                : fields[index].key);
          }

          if (fields[index].type == "OPERATOR") {
            formula += " " + fields[index].value;
          }

          if (fields[index].type == "PARENTHESES") {
            formula += " " + fields[index].value;
          }

          if (fields[index].type == "FORMULA") {
            formula += " " + fields[index].formula;
          }

          if (fields[index].type == "NUMBER") {
            formula += " " + fields[index].number;
          }

          if (fields[index].type == "GLOBAL_VARIABLE") {
            formula += " " + fields[index].field_name;
          }

          if (fields[index].type == "DATE") {
            formula += " " + fields[index].date;
          }
        }
      }
      return formula;
    },
    getNumberColumnsFields() {
      if (this.payload?.main_columns) {
        let allowed = ["NUMBER", "CURRENCY", "AGGREGATE_FUNCTION", "DATE"];
        return this.payload.main_columns.filter(
          (e) =>
            ["SUM", "AVG", "COUNT", "FORMULA"].indexOf(e.field_type) != -1 ||
            allowed.includes(e.input_type) ||
            (e.input_type == "FORMULA" && allowed.includes(e.result_type))
        );
      }
      return [];
    },
    getNumberColumnsFieldsAggregation() {
      if (this.payload?.main_columns) {
        let allowed = ["NUMBER", "CURRENCY", "AGGREGATE_FUNCTION"];
        let toBeAggregatedFields = [];
        this.payload.main_columns.forEach((e) => {
          if (
            ["SUM", "AVG", "COUNT"].indexOf(e.field_type) != -1 ||
            allowed.includes(e.input_type) ||
            (e.input_type == "FORMULA" && allowed.includes(e.result_type))
          ) {
            toBeAggregatedFields = [
              ...toBeAggregatedFields,
              ...[
                {
                  ...e,
                  ...{
                    key: "sum#" + e.key,
                    label: "Sum - " + e.label,
                    filter: ![null, "", undefined].includes(e.filter)
                      ? e.filter
                      : "",
                  },
                },
                {
                  ...e,
                  ...{
                    key: "avg#" + e.key,
                    label: "Average - " + e.label,
                    filter: ![null, "", undefined].includes(e.filter)
                      ? e.filter
                      : "",
                  },
                },
              ],
            ];
          }
        });
        return toBeAggregatedFields;
      }
      return [];
    },
    getResultTypes() {
      return this.newAggregationObject?.selected_fields?.filter(
        (fd) => fd.result_type != ""
      ).length
        ? [
            this.newAggregationObject?.selected_fields?.find(
              (fd) => fd.result_type != ""
            ),
          ]
        : [];
    },
    getEntityTypeColoumns() {
      return (this.payload.filter_fields || []).filter((filter) => {
        if (filter.type == "ENTITY" && !filter.multiple && filter.entity_id) {
          return true;
        }
      });
    },
    getRelatedNestedRelatedFilters() {
      return this.selectedOtherEntities.filter((fd) => fd.includes("@"));
    },
  },
  methods: {
    getSelfFilterLabel(filter) {
      if (filter.split("@")[1] == "relationship") {
        return (
          "Self - Related to " +
          this.relationshipsData.find(
            (e) => e.relationship_id == filter.split("@")[0]
          ).relationship_title
        );
      } else {
        return (
          "Nested - Related to " +
          this.nestedRelationshipsData.find(
            (e) =>
              e.relationship_id == filter.split("@")[0] &&
              e.parent_relationship_details.relationship_id ==
                filter.split("@")[1].split("-")[1]
          ).relationship_title
        );
      }
    },
    rulesFields() {
      let fields = [];
      (this.payload.main_columns || []).forEach((col) => {
        if (col.template_id) {
          let templateFields = this.templatesFieldsMap?.[col.template_id] || [];
          let selectedField = (templateFields || []).find(
            (e) => e.key == col.key
          );
          if (selectedField) {
            fields.push({ ...selectedField, ...{ label: col.label } });
          }
        }
      });
      return fields;
    },
    deleteFormRules(index) {
      this.payload.rules.splice(index.index, 1);
    },
    updateFormRules(data) {
      if (data.index == -1) {
        this.payload.rules.push(data);
      } else {
        this.payload.rules[data.index] = { ...data };
      }
    },
    goBackConfirm() {
      this.$confirm(
        "It looks like you have unsaved changes. If you leave now, your changes will be lost.",
        "Warning",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "warning",
        }
      ).then(() => {
        this.goBack();
      });
    },
    showPrimaryFilter(filter) {
      if (
        (filter.multiple || !filter.main_entity_field) &&
        !this.payload.filter_fields.filter(
          (fd) => fd.primary_filter && fd.key != filter.key
        ).length
      ) {
        return true;
      }
      return false;
    },
    checkPickerOption(op) {
      if (this.payload?.filter_fields) {
        let existedDateFilters = this.payload.filter_fields.filter(
          (e) => e.type == "DATE"
        );
        if (existedDateFilters && existedDateFilters.length) {
          return existedDateFilters?.[0]?.filter_type == op;
        }
      }
      return false;
    },
    addAggregation(index) {
      if (index > -1) {
        this.newAggregationObject = JSON.parse(
          JSON.stringify(this.payload.aggregations[index])
        );
      } else {
        this.newAggregationObject = {
          label: "",
          type: "Display",
          selected_fields: [],
          displayField: "",
          result_type: "",
        };
      }
      this.selectedFilterIndex = index;
      this.addNewFilterModal = true;
    },
    checkselectedEntities(value) {
      let exist = this.checkEntitySelected(value);
      if (exist) {
        this.selectedOtherEntities.unshift(value);
      }
    },
    checkEntitySelected(entityField) {
      let [entity] = entityField.includes("@")
        ? entityField.split("@")
        : entityField.split("#");
      if (this.selectedOtherEntities?.length) {
        if (entityField.includes("@")) {
          let existed = this.selectedOtherEntities.filter(
            (e) => e.includes("#") && e.split("#")[0] == entity
          );
          if (existed.length) {
            return true;
          }
        } else if (entityField.includes("#")) {
          let existed = this.selectedOtherEntities.filter(
            (e) => e.includes("@") && e.split("@")[0] == entity
          );
          if (existed.length) {
            return true;
          }
        }
      }
      if (this.payload?.filter_fields) {
        let relatedFilter = this.payload.filter_fields.find(
          (e) =>
            e.linked_entity_id == entity ||
            (e.linked_entity_ids || [])
              .flatMap((l) => l.entity_id)
              .indexOf(entity) !== -1
        );
        if (relatedFilter) {
          return true;
        }
      }
      if (this.payload?.main_columns) {
        let relatedFilter = this.payload.main_columns.find(
          (e) => e.linked_entity_id == entity
        );
        if (relatedFilter) {
          return true;
        }
      }
      return false;
    },
    async saveViewData() {
      let check = this.checkValidData();
      if (check) {
        let form = this.prepareData();
        if (this.entityViewDataId) {
          this.loading = true;
          this.loadingText = "Updating...";
          let response = await postAPICall(
            "PATCH",
            `entity-view-configuration/${this.entityViewDataId}`,
            {
              id: this.entityViewDataId,
              ...form,
            }
          );
          if (response?.data) {
            await updateEntityViewData(this.entityViewDataId, response?.data);
            this.$notify.success({
              title: "Success",
              message: "Entity view updated successfully",
            });
            this.goBack();
          } else {
            this.$notify.error({
              title: "Error",
              message: "Error while updating entity view",
            });
          }
          this.loading = false;
        } else {
          this.loading = true;
          this.loadingText = "Creating...";
          let responseData = await postAPICall(
            "POST",
            `entity-view-configuration`,
            form
          );
          if (responseData.data) {
            await addSingleEntityViewToLocal(responseData.data);
            this.$notify.success({
              title: "Success",
              message: "Entity view created successfully",
            });
            this.goBack();
          } else {
            this.$notify.error({
              title: "Error",
              message: "Error while creating entity view",
            });
          }
          this.loading = false;
        }
      }
    },
    prepareData() {
      return {
        ...this.payload,
        ["other_entities"]: this.selectedOtherEntities.map((e) => {
          return {
            entity_id: e.includes("@") ? e.split("@")[0] : e.split("#")[0],
            linked_field: e.includes("@")
              ? e.split("@")[1]
              : e.split("#")[1] + "#" + e.split("#")[2],
          };
        }),
      };
    },
    checkValidData() {
      if (!this.payload.name) {
        this.$message({
          message: "Warning, Name is required. Please enter name",
          type: "warning",
        });
        return false;
      }
      if (!this.payload.entity_id) {
        this.$message({
          message: "Warning, Select main entity. Please select entity",
          type: "warning",
        });
        return false;
      }
      if (!this.payload.main_columns || !this.payload.main_columns.length) {
        this.$message({
          message: "Warning, Atleast one column required",
          type: "warning",
        });
        return false;
      }
      // if (
      //   this.payload?.printing_document &&
      //   this.payload?.send_attachment_to &&
      //   !this.payload.attachment_email_template
      // ) {
      //   this.$message({
      //     message: "Warning, Please select email template",
      //     type: "warning",
      //   });
      //   return false;
      // }
      return true;
    },
    refreshFormulaFields() {
      this.refreshFields = true;
      setTimeout(() => {
        this.refreshFields = false;
      });
    },
    onSelectOperator(operator, aggregation = false) {
      if (aggregation) {
        if (
          this.activeClassIndex > -1 &&
          this.newAggregationObject.selected_fields[this.activeClassIndex]
            .type == "OPERATOR"
        ) {
          this.newAggregationObject.selected_fields[this.activeClassIndex] = {
            type: "OPERATOR",
            value: operator,
          };
          this.showSelectOperator = "";
          this.activeClassIndex = -1;
        } else {
          this.newAggregationObject.selected_fields.push({
            type: "OPERATOR",
            value: operator,
          });
          this.showSelectOperator = "";
        }
      } else {
        if (
          this.activeClassIndex > -1 &&
          this.newFiltetObject.selected_fields[this.activeClassIndex].type ==
            "OPERATOR"
        ) {
          this.newFiltetObject.selected_fields[this.activeClassIndex] = {
            type: "OPERATOR",
            value: operator,
          };
          this.showSelectOperator = "";
          this.activeClassIndex = -1;
        } else {
          this.newFiltetObject.selected_fields.push({
            type: "OPERATOR",
            value: operator,
          });
          this.showSelectOperator = "";
        }
      }
      this.refreshFormulaFields();
    },
    onSelectParentheses(parentheses, aggregation = false) {
      if (aggregation) {
        this.newAggregationObject.selected_fields.push({
          type: "PARENTHESES",
          value: parentheses,
        });
      } else {
        this.newFiltetObject.selected_fields = [
          ...(this.newFiltetObject.selected_fields || []),
          {
            type: "PARENTHESES",
            value: parentheses,
          },
        ];
      }
      this.showSelectParentheses = "";
      this.refreshFormulaFields();
    },
    popAggregationField() {
      this.newAggregationObject.selected_fields.pop();
      this.refreshFormulaFields();
    },
    popField() {
      this.newFiltetObject.selected_fields.pop();
      this.refreshFormulaFields();
    },
    addCurrentDate() {
      if (this.activeClassIndex > -1) {
        let fieldData =
          this.newFiltetObject.selected_fields[this.activeClassIndex];
        if (
          fieldData.type == "FIELD" ||
          fieldData.type == "FORMULA" ||
          fieldData.type == "NUMBER" ||
          fieldData.type == "DATE" ||
          fieldData.type == "CURRENCY" ||
          fieldData.type == "TIME" ||
          fieldData.type == "LIST" ||
          fieldData.type == "GLOBAL_VARIABLE"
        ) {
          this.newFiltetObject.selected_fields[this.activeClassIndex] = {
            type: "CURRENT_DATE",
          };
          this.activeClassIndex = -1;
        }
      } else {
        this.newFiltetObject.selected_fields.push({
          type: "CURRENT_DATE",
        });
      }
      this.refreshFormulaFields();
    },
    addNumber(aggregation = false) {
      if (aggregation) {
        if (this.activeClassIndex > -1) {
          let fieldData =
            this.newAggregationObject.selected_fields[this.activeClassIndex];
          if (
            fieldData.type == "FIELD" ||
            fieldData.type == "FORMULA" ||
            fieldData.type == "NUMBER" ||
            fieldData.type == "DATE" ||
            fieldData.type == "CURRENCY" ||
            fieldData.type == "TIME" ||
            fieldData.type == "LIST" ||
            fieldData.type == "GLOBAL_VARIABLE"
          ) {
            let number = this.manualNumber;
            this.newAggregationObject.selected_fields[this.activeClassIndex] = {
              type: fieldData.type == "DATE" ? "DATE" : "NUMBER",
              number,
            };
            this.manualNumber = "";
            this.activeClassIndex = -1;
          }
        } else {
          if (this.manualNumber > 0) {
            let number = this.manualNumber;
            this.newAggregationObject.selected_fields.push({
              type: "NUMBER",
              value: number,
            });
            this.manualNumber = "";
          }
        }
      } else {
        if (this.activeClassIndex > -1) {
          let fieldData =
            this.newFiltetObject.selected_fields[this.activeClassIndex];
          if (
            fieldData.type == "FIELD" ||
            fieldData.type == "FORMULA" ||
            fieldData.type == "NUMBER" ||
            fieldData.type == "DATE" ||
            fieldData.type == "CURRENCY" ||
            fieldData.type == "TIME" ||
            fieldData.type == "LIST" ||
            fieldData.type == "GLOBAL_VARIABLE"
          ) {
            let number = this.manualNumber;
            this.newFiltetObject.selected_fields[this.activeClassIndex] = {
              type: fieldData.type == "DATE" ? "DATE" : "NUMBER",
              number,
            };
            this.manualNumber = "";
            this.activeClassIndex = -1;
          }
        } else {
          if (this.manualNumber > 0) {
            let number = this.manualNumber;
            this.newFiltetObject.selected_fields.push({
              type: "NUMBER",
              value: number,
            });
            this.manualNumber = "";
          }
        }
      }
      this.refreshFormulaFields();
    },
    getFieldLabel(item) {
      if (this.payload?.main_columns) {
        let col = this.payload.main_columns.find(
          (e) =>
            e.key == item.key &&
            e.template_id == item.template_id &&
            ((e.filter && e.filter == item.filter) || !e.filter)
        );
        if (col?.label) {
          return col.label;
        }
      }
      return this.capitalizeFirstLetter(item.key.replaceAll("_", " "));
    },
    getAggregationFieldLabel(item) {
      let [key, filter] = item.includes("@") ? item.split("@") : [item, ""];
      return this.getNumberColumnsFieldsAggregation
        .find(
          (e) =>
            e.key == key.split("#")[0] + "#" + key.split("#")[3] &&
            (e?.filter == filter || (filter == "" && !e?.filter))
        )
        .label.replace("-", "of");
    },
    getAggregationFormulaLabel(selected_fields) {
      let formula = "";
      selected_fields.map((e) => {
        if (e.type == "FIELD") {
          formula += e.label.replace("-", "of");
        } else {
          formula += " " + e.value + " ";
        }
      });
      return formula;
    },
    onSelectField(item) {
      let obj = {
        type: "FIELD",
        key: item.key,
        template_id: item.template_id,
        selected_date_offset: item.selected_date_offset,
        filter: item?.filter ? item.filter : "",
      };
      if (
        this.newFiltetObject?.selected_fields &&
        this.newFiltetObject.selected_fields.length
      ) {
        this.newFiltetObject.selected_fields.push(obj);
      } else {
        this.newFiltetObject.selected_fields = [obj];
      }
      this.showSelectField = "";
      this.refreshFormulaFields();
    },
    onSelectAggregation(item) {
      let [key, filter] = item.includes("@") ? item.split("@") : [item, ""];
      let itemField = this.getNumberColumnsFieldsAggregation.find(
        (e) => e.key == key && filter == e?.filter
      );
      let obj = {
        type: "FIELD",
        agg_type: this.showSelectField.split("#")[0] == "sum" ? "SUM" : "AVG",
        key: itemField.key.split("#")[1],
        template_id: itemField.template_id,
        label: itemField.label,
        linked_entity_id: itemField.linked_entity_id,
        result_type: itemField.result_type ? itemField.result_type : "",
        filter: itemField?.filter ? itemField?.filter : "",
      };
      if (
        this.newAggregationObject?.selected_fields &&
        this.newAggregationObject.selected_fields.length
      ) {
        this.newAggregationObject.selected_fields.push(obj);
      } else {
        this.newAggregationObject.selected_fields = [obj];
      }
      this.showSelectField = "";
      this.refreshFormulaFields();
    },
    showFieldButton(type) {
      if (
        type == "FIELD" ||
        type == "NUMBER" ||
        type == "TIME" ||
        type == "DATE_TIME_RANGE" ||
        type == "DATE_RANGE" ||
        type == "LIST" ||
        type == "DATE" ||
        type == "CURRENCY" ||
        type == "GLOBAL_VARIABLE" ||
        type == "FORMULA"
      ) {
        return true;
      } else {
        return false;
      }
    },
    getOperationType(operator) {
      if (operator == "+") {
        return "success";
      } else if (operator == "-") {
        return "primary";
      } else if (operator == "*") {
        return "info";
      } else if (operator == "/") {
        return "warning";
      }
    },
    getActiveClass(index) {
      if (this.activeClassIndex == index) {
        return "active";
      }
    },
    swapFields(index) {
      if (this.activeClassIndex > -1) {
        this.activeClassIndex = -1;
      } else {
        this.activeClassIndex = index;
      }
      this.refreshFormulaFields();
    },
    checkDisabled(type, item = "") {
      if (
        type == "field" ||
        type == "number" ||
        type == "currency" ||
        type == "global_variable" ||
        type == "date" ||
        type == "time" ||
        type == "list"
      ) {
        if (
          this.newFiltetObject?.selected_fields &&
          this.newFiltetObject.selected_fields.length
        ) {
          if (this.activeClassIndex > -1) {
            let fieldData =
              this.newFiltetObject.selected_fields[this.activeClassIndex];
            if (
              fieldData &&
              (fieldData.type == "FIELD" ||
                fieldData.type == "NUMBER" ||
                fieldData.type == "DATE" ||
                fieldData.type == "TIME" ||
                fieldData.type == "LIST" ||
                fieldData.type == "CURRENCY" ||
                fieldData.type == "GLOBAL_VARIABLE" ||
                fieldData.type == "FORMULA")
            ) {
              return false;
            } else {
              return true;
            }
          } else {
            return (
              this.newFiltetObject?.selected_fields &&
              (this.newFiltetObject.selected_fields[
                this.newFiltetObject.selected_fields.length - 1
              ].type == "FIELD" ||
                this.newFiltetObject.selected_fields[
                  this.newFiltetObject.selected_fields.length - 1
                ].type == "NUMBER" ||
                this.newFiltetObject.selected_fields[
                  this.newFiltetObject.selected_fields.length - 1
                ].type == "DATE" ||
                this.newFiltetObject.selected_fields[
                  this.newFiltetObject.selected_fields.length - 1
                ].type == "TIME" ||
                this.newFiltetObject.selected_fields[
                  this.newFiltetObject.selected_fields.length - 1
                ].type == "DATE_TIME_RANGE" ||
                this.newFiltetObject.selected_fields[
                  this.newFiltetObject.selected_fields.length - 1
                ].type == "DATE_RANGE" ||
                this.newFiltetObject.selected_fields[
                  this.newFiltetObject.selected_fields.length - 1
                ].type == "LIST" ||
                this.newFiltetObject.selected_fields[
                  this.newFiltetObject.selected_fields.length - 1
                ].type == "GLOBAL_VARIABLE" ||
                this.newFiltetObject.selected_fields[
                  this.newFiltetObject.selected_fields.length - 1
                ].type == "FORMULA" ||
                this.newFiltetObject.selected_fields[
                  this.newFiltetObject.selected_fields.length - 1
                ].type == "CURRENCY" ||
                (this.newFiltetObject.selected_fields[
                  this.newFiltetObject.selected_fields.length - 1
                ].type == "PARENTHESES" &&
                  this.newFiltetObject.selected_fields[
                    this.newFiltetObject.selected_fields.length - 1
                  ].value == ")"))
            );
          }
        }
        return false;
      } else if (type == "operator") {
        if (
          this.newFiltetObject?.selected_fields &&
          this.newFiltetObject.selected_fields.length
        ) {
          if (this.activeClassIndex > -1) {
            let fieldData =
              this.newFiltetObject.selected_fields[this.activeClassIndex];
            if (fieldData && fieldData.type == "OPERATOR") {
              return false;
            } else {
              return true;
            }
          } else {
            return (
              this.newFiltetObject.selected_fields[
                this.newFiltetObject.selected_fields.length - 1
              ].type == "OPERATOR" ||
              (this.newFiltetObject.selected_fields[
                this.newFiltetObject.selected_fields.length - 1
              ].type == "PARENTHESES" &&
                this.newFiltetObject.selected_fields[
                  this.newFiltetObject.selected_fields.length - 1
                ].value == "(")
            );
          }
        }
        return true;
      } else if (type == "parentheses") {
        if (this.activeClassIndex > -1) {
          return true;
        } else {
          let openParenthesesCount = 0;
          let lastOpenIndex = -1;
          let closeParenthesesCount = 0;
          if (this.newFiltetObject && this.newFiltetObject.selected_fields) {
            let selectedFieldsLength =
              this.newFiltetObject && this.newFiltetObject.selected_fields
                ? this.newFiltetObject.selected_fields.length
                : 0;
            let index = -1;
            this.newFiltetObject.selected_fields.map((el) => {
              index += 1;
              if (el.type == "PARENTHESES") {
                if (el.value == "(") {
                  openParenthesesCount += 1;
                  lastOpenIndex = index;
                } else {
                  closeParenthesesCount += 1;
                }
                return;
              }
            });
            let afterLastOpenLength =
              selectedFieldsLength - (lastOpenIndex + 1);
            if (!selectedFieldsLength) {
              return item == ")";
            } else if (selectedFieldsLength < 3) {
              //condition for formula
              return (
                this.newFiltetObject.selected_fields[selectedFieldsLength - 1]
                  .type != "OPERATOR" || item == ")"
              );
            } else {
              if (item == "(") {
                if (
                  this.newFiltetObject.selected_fields[selectedFieldsLength - 1]
                    .type == "OPERATOR"
                ) {
                  return false;
                } else if (
                  this.newFiltetObject.selected_fields[selectedFieldsLength - 1]
                    .type == "PARENTHESES" &&
                  this.newFiltetObject.selected_fields[selectedFieldsLength - 1]
                    .value == "("
                ) {
                  return false;
                } else {
                  return true;
                }
              } else {
                if (
                  this.newFiltetObject.selected_fields[selectedFieldsLength - 1]
                    .type == "OPERATOR"
                ) {
                  return true;
                } else if (openParenthesesCount <= closeParenthesesCount) {
                  return true;
                } else if (afterLastOpenLength < 2) {
                  return true;
                } else if (
                  afterLastOpenLength > 2 &&
                  afterLastOpenLength % 2 == 0
                ) {
                  if (
                    this.newFiltetObject.selected_fields[
                      selectedFieldsLength - 1
                    ].type == "PARENTHESES" &&
                    this.newFiltetObject.selected_fields[
                      selectedFieldsLength - 1
                    ].value == ")"
                  ) {
                    return false;
                  }
                  return true;
                } else {
                  return false;
                }
              }
            }
          }
        }
      } else {
        return false;
      }
    },
    checkAggregationDisabled(type, item = "") {
      if (
        type == "field" ||
        type == "number" ||
        type == "currency" ||
        type == "global_variable" ||
        type == "date" ||
        type == "time" ||
        type == "list"
      ) {
        if (
          this.newAggregationObject?.selected_fields &&
          this.newAggregationObject.selected_fields.length
        ) {
          if (this.activeClassIndex > -1) {
            let fieldData =
              this.newAggregationObject.selected_fields[this.activeClassIndex];
            if (
              fieldData &&
              (fieldData.type == "FIELD" ||
                fieldData.type == "NUMBER" ||
                fieldData.type == "DATE" ||
                fieldData.type == "TIME" ||
                fieldData.type == "LIST" ||
                fieldData.type == "CURRENCY" ||
                fieldData.type == "GLOBAL_VARIABLE" ||
                fieldData.type == "FORMULA")
            ) {
              return false;
            } else {
              return true;
            }
          } else {
            return (
              this.newAggregationObject?.selected_fields &&
              (this.newAggregationObject.selected_fields[
                this.newAggregationObject.selected_fields.length - 1
              ].type == "FIELD" ||
                this.newAggregationObject.selected_fields[
                  this.newAggregationObject.selected_fields.length - 1
                ].type == "NUMBER" ||
                this.newAggregationObject.selected_fields[
                  this.newAggregationObject.selected_fields.length - 1
                ].type == "DATE" ||
                this.newAggregationObject.selected_fields[
                  this.newAggregationObject.selected_fields.length - 1
                ].type == "TIME" ||
                this.newAggregationObject.selected_fields[
                  this.newAggregationObject.selected_fields.length - 1
                ].type == "DATE_TIME_RANGE" ||
                this.newAggregationObject.selected_fields[
                  this.newAggregationObject.selected_fields.length - 1
                ].type == "DATE_RANGE" ||
                this.newAggregationObject.selected_fields[
                  this.newAggregationObject.selected_fields.length - 1
                ].type == "LIST" ||
                this.newAggregationObject.selected_fields[
                  this.newAggregationObject.selected_fields.length - 1
                ].type == "GLOBAL_VARIABLE" ||
                this.newAggregationObject.selected_fields[
                  this.newAggregationObject.selected_fields.length - 1
                ].type == "FORMULA" ||
                this.newAggregationObject.selected_fields[
                  this.newAggregationObject.selected_fields.length - 1
                ].type == "CURRENCY" ||
                (this.newAggregationObject.selected_fields[
                  this.newAggregationObject.selected_fields.length - 1
                ].type == "PARENTHESES" &&
                  this.newAggregationObject.selected_fields[
                    this.newAggregationObject.selected_fields.length - 1
                  ].value == ")"))
            );
          }
        }
        return false;
      } else if (type == "operator") {
        if (
          this.newAggregationObject?.selected_fields &&
          this.newAggregationObject.selected_fields.length
        ) {
          if (this.activeClassIndex > -1) {
            let fieldData =
              this.newAggregationObject.selected_fields[this.activeClassIndex];
            if (fieldData && fieldData.type == "OPERATOR") {
              return false;
            } else {
              return true;
            }
          } else {
            return (
              this.newAggregationObject.selected_fields[
                this.newAggregationObject.selected_fields.length - 1
              ].type == "OPERATOR" ||
              (this.newAggregationObject.selected_fields[
                this.newAggregationObject.selected_fields.length - 1
              ].type == "PARENTHESES" &&
                this.newAggregationObject.selected_fields[
                  this.newAggregationObject.selected_fields.length - 1
                ].value == "(")
            );
          }
        }
        return true;
      } else if (type == "parentheses") {
        if (this.activeClassIndex > -1) {
          return true;
        } else {
          let openParenthesesCount = 0;
          let lastOpenIndex = -1;
          let closeParenthesesCount = 0;
          if (
            this.newAggregationObject &&
            this.newAggregationObject.selected_fields
          ) {
            let selectedFieldsLength =
              this.newAggregationObject &&
              this.newAggregationObject.selected_fields
                ? this.newAggregationObject.selected_fields.length
                : 0;
            let index = -1;
            this.newAggregationObject.selected_fields.map((el) => {
              index += 1;
              if (el.type == "PARENTHESES") {
                if (el.value == "(") {
                  openParenthesesCount += 1;
                  lastOpenIndex = index;
                } else {
                  closeParenthesesCount += 1;
                }
                return;
              }
            });
            let afterLastOpenLength =
              selectedFieldsLength - (lastOpenIndex + 1);
            if (!selectedFieldsLength) {
              return item == ")";
            } else if (selectedFieldsLength < 3) {
              //condition for formula
              return (
                this.newAggregationObject.selected_fields[
                  selectedFieldsLength - 1
                ].type != "OPERATOR" || item == ")"
              );
            } else {
              if (item == "(") {
                if (
                  this.newAggregationObject.selected_fields[
                    selectedFieldsLength - 1
                  ].type == "OPERATOR"
                ) {
                  return false;
                } else if (
                  this.newAggregationObject.selected_fields[
                    selectedFieldsLength - 1
                  ].type == "PARENTHESES" &&
                  this.newAggregationObject.selected_fields[
                    selectedFieldsLength - 1
                  ].value == "("
                ) {
                  return false;
                } else {
                  return true;
                }
              } else {
                if (
                  this.newAggregationObject.selected_fields[
                    selectedFieldsLength - 1
                  ].type == "OPERATOR"
                ) {
                  return true;
                } else if (openParenthesesCount <= closeParenthesesCount) {
                  return true;
                } else if (afterLastOpenLength < 2) {
                  return true;
                } else if (
                  afterLastOpenLength > 2 &&
                  afterLastOpenLength % 2 == 0
                ) {
                  if (
                    this.newAggregationObject.selected_fields[
                      selectedFieldsLength - 1
                    ].type == "PARENTHESES" &&
                    this.newAggregationObject.selected_fields[
                      selectedFieldsLength - 1
                    ].value == ")"
                  ) {
                    return false;
                  }
                  return true;
                } else {
                  return false;
                }
              }
            }
          }
        }
      } else {
        return false;
      }
    },
    getRelatedFiltersEntities(fields) {
      fields = fields.filter((fd) => fd.type != "CONTENT");
      if (this.newFiltetObject?.field_type == "FILTER") {
        if (this.payload?.filter_fields) {
          return fields.filter((el) => {
            let relatedFilter = this.payload.filter_fields.find(
              (e) =>
                e.type == el.inputType &&
                ((!e.entity_id && !el.entity_id) || e.entity_id == el.entity_id)
            );
            if (relatedFilter) {
              return true;
            }
          });
        }
        return [];
      } else if (
        ["AVG", "SUM"].indexOf(this.newFiltetObject?.field_type) != -1
      ) {
        let numberTypes = ["NUMBER", "CURRENCY", "AGGREGATE_FUNCTION"];
        return fields.filter((field) => {
          if (numberTypes.indexOf(field.inputType) !== -1) {
            return true;
          } else if (
            field.inputType == "FORMULA" &&
            numberTypes.indexOf(field.result_type) !== -1
          ) {
            return true;
          }
        });
      }
      return fields;
    },
    checkFieldType(type) {
      let numberTypes = ["NUMBER", "FORMULA", "CURRENCY", "AGGREGATE_FUNCTION"];
      if (type == "FILTER") {
        if (this.payload?.filter_fields) {
          let linkedFilter = this.payload.filter_fields.find((e) => {
            if (
              e?.type == "ENTITY" &&
              e.entity_id == this.newFiltetObject.entity_id
            ) {
              return true;
            }
            return false;
          });
          if (linkedFilter) {
            return true;
          }
        }
        return false;
      } else if (type == "DISPLAY") {
        if (
          this.newFiltetObject?.main_entity_field ||
          this.newFiltetObject?.linked_entity_id == this.payload.entity_id
        ) {
          return true;
        }
        return false;
      } else if (type == "FORMULA") {
        return false;
      } else if (type == "SUM" || type == "AVG" || type == "COUNT") {
        let selectedField = this.getSelectedEntityFields(
          [],
          this.newFiltetObject.linked_entity_id
        ).find(
          (field) =>
            this.newFiltetObject.template_id + "#" + this.newFiltetObject.key ==
            field.template_id + "#" + field.key
        );
        if (numberTypes.indexOf(selectedField?.inputType) !== -1) {
          return true;
        }
        return false;
      }
      return false;
    },
    addToOtherEntityFilters() {
      if (this.tempOtherFilterField) {
        let field = this.getOtherEntityFields([
          "DATE",
          "DATE_TIME",
          ...this.selectionFields,
        ]).find((e) =>
          this.tempOtherFilterField.includes(e.entity_id + "#" + e.key)
        );
        if (field?.type) {
          this.newFiltetObject.type = field.type;
        }
        if (field?.type == "ENTITY") {
          this.newFiltetObject.entity_id = field.field_entity_id;
        }
        if (!this.newFiltetObject.key) {
          this.newFiltetObject.key = this.tempOtherFilterField;
        }
        this.newFiltetObject.linked_entity_ids = Array.from(
          new Set([
            ...(this.newFiltetObject.linked_entity_ids || []),
            this.tempOtherFilterField,
          ])
        );
      } else {
        this.newFiltetObject.linked_entity_ids = [];
        this.newFiltetObject.type = "";
      }
    },
    setCurrentEntityDetails(value) {
      if (value) {
        this.newFiltetObject.entity_id = this.payload.entity_id;
        this.newFiltetObject.linked_entity_id = this.payload.entity_id;
        this.newFiltetObject.linked_entity_ids = [];
        this.newFiltetObject.main_entity_field = false;
        this.newFiltetObject.type = "ENTITY";
        this.newFiltetObject.key = "currentEntity";
      } else {
        this.newFiltetObject.entity_id = "";
        this.newFiltetObject.linked_entity_id = "";
        this.newFiltetObject.linked_entity_ids = [];
        this.newFiltetObject.main_entity_field = false;
        this.newFiltetObject.type = "";
        this.newFiltetObject.key = "";
      }
    },
    setColumnDetails(value) {
      if (value) {
        let field = this.getSelectedEntityFields(
          [],
          this.newFiltetObject.linked_entity_id
        ).find((field) => value == field.key);
        if (field) {
          if (field.inputType == "ENTITY" && field?.entity_id) {
            this.newFiltetObject.entity_id = field.entity_id;
          }
          if (field.inputType == "FORMULA") {
            this.newFiltetObject.result_type = field.result_type;
          }
          this.newFiltetObject.template_id = field.template_id;
          this.newFiltetObject.detailed_view_type = "ENTITY";
          this.newFiltetObject.input_type = field.inputType;
        }
      } else {
        delete this.newFiltetObject.entity_id;
      }
    },
    setDateFilter(value) {
      if (value) {
        if (value == "PICKER_OPTIONS") {
          if (!this.newFiltetObject.key.includes("/picker")) {
            this.newFiltetObject.key = this.newFiltetObject.key + "/picker";
          }
        } else {
          if (this.newFiltetObject.key.includes("/picker")) {
            this.newFiltetObject.key = this.newFiltetObject.key.replace(
              "/picker",
              ""
            );
          }
        }
      } else {
        delete this.newFiltetObject.entity_id;
      }
    },
    setFilterDetails(value) {
      if (value) {
        if (value.includes("@")) {
          this.newFiltetObject.type = "RELATION";
          this.newFiltetObject.entity_id = value.split("@")[0];
        } else {
          let field = this.getSelectedEntityFields(
            ["DATE", "DATE_TIME", ...this.selectionFields],
            this.newFiltetObject.linked_entity_id
          ).find((field) => value == field.template_id + "#" + field.key);
          if (field) {
            if (field.inputType == "ENTITY" && field?.entity_id) {
              this.newFiltetObject.entity_id = field.entity_id;
            }
            if(field.input_type == 'ENTITY_VARIABLE' && field?.global_variable_entity_field){
              this.newFiltetObject.parent_field = field.parent_field;
            }
            this.newFiltetObject.type =
              field.inputType == "DATE_TIME" ? "DATE" : field.inputType == "FORMULA" ? field.result_type : field.inputType;
          }
        }
      } else {
        delete this.newFiltetObject.entity_id;
      }
    },
    updateEntityId(value) {
      if (value) {
        this.$set(
          this.newFiltetObject,
          "linked_entity_id",
          this.payload.entity_id
        );
        this.$set(this.newFiltetObject, "key", "");
      } else {
        delete this.newFiltetObject.linked_entity_id;
      }
    },
    getOtherEntityFields(types) {
      let result = [];
      if (this.selectedOtherEntities) {
        this.selectedOtherEntities.forEach((e) => {
          let [entity] = e.includes("@") ? e.split("@") : e.split("#");
          let fields = this.getSelectedEntityFields(types, entity);
          result = [
            ...result,
            ...fields.flatMap((f) => {
              return {
                entity_id: entity,
                field_entity_id: f.entity_id,
                key: f.key,
                label: f.label,
                type: f.inputType == "FORMULA" ? f.result_type : f.inputType,
              };
            }),
          ];
        });
      }
      return result;
    },
    eliminateDateFilters(fields) {
      return fields.filter((field) => {
        if (this.payload?.filter_fields) {
          let existedDateFilters = this.payload.filter_fields.filter(
            (e) => e.type == "DATE"
          );
          if (existedDateFilters.length == 2) {
            return (
              ["DATE", "DATE_TIME"].indexOf(field.inputType) == -1 ||
              (this.matchFilterAndField(existedDateFilters, field) &&
                this.selectedFilterIndex > -1)
            );
          } else if (
            existedDateFilters.length == 1 &&
            existedDateFilters?.[0]?.key
          ) {
            return (
              ["DATE", "DATE_TIME"].indexOf(field.inputType) == -1 ||
              this.matchFilterAndField(existedDateFilters, field)
            );
          }
        }
        return true;
      });
    },
    matchFilterAndField(filter, field) {
      if (filter?.[0]?.key) {
        let [temp, key] = filter[0].key.split("#");
        if (key.includes("/picker")) {
          key = key.replace("/picker", "");
        }
        if (key.includes("/range")) {
          key = key.replace("/range", "");
        }
        return field.key == key && field.template_id == temp;
      }
      return true;
    },
    getSelectedEntityFields(types, entityId) {
      if (
        entityId &&
        this.selectedEntitiesData &&
        this.selectedEntitiesData[entityId]
      ) {
        if (types && types.length) {
          return this.selectedEntitiesData[entityId].filter(
            (e) => types.indexOf(e.inputType) !== -1 || types.indexOf(e.result_type) !== -1
          );
        }
        return this.selectedEntitiesData[entityId];
      }
      return [];
    },
    async getSelectedEntityInto() {
      this.fieldsFromColumns = this.rulesFields();
      if (this.activetab != "basic_details") {
        let requiredEntities = [];
        if (this.selectedOtherEntities && this.selectedOtherEntities.length) {
          this.selectedOtherEntities.forEach((entity) => {
            let entityId = entity.includes("@")
              ? entity.split("@")[0]
              : entity.split("#")[0];
            if (!this.selectedEntitiesData[entityId]) {
              requiredEntities.push(entityId);
            }
          });
        }
        if (
          this.payload.entity_id &&
          !this.selectedEntitiesData[this.payload.entity_id]
        ) {
          requiredEntities.push(this.payload.entity_id);
        }
        if (requiredEntities && requiredEntities.length) {
          this.fetchDetailedEntities(requiredEntities);
        }
      }
    },
    async fetchDetailedEntities(requiredEntities) {
      this.loading = true;
      this.loadingText = "Fetching selected entities details...";
      let params = {
        ids: requiredEntities,
      };
      let [allFilters, entity] = await Promise.all([
        postAPICall("GET", "filters/fetch-all-filters-by-entity-ids", {
          entityIds: requiredEntities,
        }),
        postAPICall("POST", "/entities", params),
      ]);
      (allFilters?.data || []).map((flt) => {
        this.allEntityFilters[flt.entity_id] = [
          ...(this.allEntityFilters?.[flt?.entity_id] || []),
          flt,
        ];
      });
      this.loading = false;
      this.loadingText = "Loading";
      if (entity?.data) {
        this.selectedEntitiesDataArray = [
          ...this.selectedEntitiesDataArray,
          ...entity?.data,
        ];
        // this.selectedEntitiesDataArray = this.selectedEntitiesDataArray.filter(
        //   (e) => this.selectedEntities.indexOf(e._id) !== -1
        // );
        entity?.data.map((currentEntity) => {
          currentEntity?.templates?.map((temp) => {
            temp.templateInfo = temp.template_id;
          })
          this.selectedEntitiesData[currentEntity._id] = this.getAllFieldsFromEntity(
            currentEntity,
            false,
            false,
            true,
            true
          );
        });
      }
    },
    resetAddingFilterModal() {
      this.addNewFilterModal = false;
      this.tempOtherFilterField = "";
      this.selectedFilterIndex = -1;
      this.newFiltetObject = {};
    },
    async openFilterModal(index, selectedData = []) {
      this.filterLoading = true;
      let requiredFilters = [];
      if (index > -1) {
        this.newFiltetObject = JSON.parse(JSON.stringify(selectedData[index]));
        if (
          selectedData[index].linked_entity_ids &&
          this.activetab == "filters"
        ) {
          this.newFiltetObject["linked_entity_ids"] = selectedData[
            index
          ].linked_entity_ids.map((e) => e.entity_id + "#" + e.key);
          this.tempOtherFilterField =
            this.newFiltetObject["linked_entity_ids"][0];
        }
        this.selectedFilterIndex = index;
        if (
          this.newFiltetObject?.type == "ENTITY" &&
          this.newFiltetObject?.entity_id
        ) {
          if (!this.selectedEntitiesData[this.newFiltetObject.entity_id]) {
            requiredFilters.push(this.newFiltetObject.entity_id);
          }
        }
      } else {
        this.newFiltetObject = {};
        this.selectedFilterIndex = -1;
      }
      if (requiredFilters && requiredFilters.length) {
        await this.fetchDetailedEntities(requiredFilters);
      }
      this.filterLoading = false;
      this.addNewFilterModal = true;
    },
    capitalizeFirstLetter(string) {
      if (string) {
        string = string.toLowerCase().replace("_", " ");
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
      return "";
    },
    removeCharactersAfterLastUnderscore(inputString) {
      const lastUnderscoreIndex = inputString.lastIndexOf("_");
      if (lastUnderscoreIndex !== -1) {
        return inputString.substring(0, lastUnderscoreIndex + 1);
      }
      return inputString;
    },
    generateKeyForField(label, keys) {
      let parsedLabel = label.trim().replace(/[^a-zA-Z 0-9]/g, "");
      parsedLabel = parsedLabel.toLowerCase().split(" ").join("_");
      if (keys && keys.includes(parsedLabel)) {
        let offsetKey = 1;
        let tempKey = parsedLabel + "_" + offsetKey;
        while (keys.includes(tempKey)) {
          offsetKey++;
          tempKey =
            this.removeCharactersAfterLastUnderscore(tempKey) + offsetKey;
        }
      }
      return parsedLabel;
    },
    addNewFilter() {
      if (
        this.newFiltetObject?.field_type == "FORMULA" &&
        !this.newFiltetObject.key
      ) {
        this.newFiltetObject.key = this.generateKeyForField(
          this.newFiltetObject.label,
          this.payload.filter_fields.flatMap((e) => e.key) || []
        );
      }
      if (
        this.newFiltetObject?.linked_entity_ids &&
        this.activetab == "filters"
      ) {
        this.newFiltetObject.linked_entity_ids =
          this.newFiltetObject.linked_entity_ids.map((e) => {
            return {
              entity_id: e.split("#")[0],
              key: e.split("#")[1] + "#" + e.split("#")[2],
            };
          });
      }
      if (this.selectedFilterIndex > -1) {
        if (this.activetab == "filters") {
          this.payload.filter_fields[this.selectedFilterIndex] = JSON.parse(
            JSON.stringify(this.newFiltetObject)
          );
        } else if (this.activetab == "aggregations") {
          this.payload.aggregations[this.selectedFilterIndex] = JSON.parse(
            JSON.stringify(this.newAggregationObject)
          );
        } else {
          this.payload.main_columns[this.selectedFilterIndex] = JSON.parse(
            JSON.stringify(this.newFiltetObject)
          );
        }
      } else {
        if (this.activetab == "filters") {
          this.payload.filter_fields.push(
            JSON.parse(JSON.stringify(this.newFiltetObject))
          );
        } else if (this.activetab == "aggregations") {
          this.payload.aggregations.push(
            JSON.parse(JSON.stringify(this.newAggregationObject))
          );
        } else {
          this.payload.main_columns.push(
            JSON.parse(JSON.stringify(this.newFiltetObject))
          );
        }
      }
      this.resetAddingFilterModal();
    },
    removeFilterField(index, fields) {
      this.$confirm(
        "Are you sure to delete this " +
          this.activetab.substring(0, this.activetab.length - 1),
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        fields.splice(index, 1);
      });
    },
    searchEntities(search_string) {
      if (search_string) {
        this.isEntitySearching = true;
        if (search_string.length > 2) {
          this.entitySearchString = search_string;
          this.entityPage = 1;
          this.fetchAllEntityies();
        }
      } else {
        this.isEntitySearching = false;
        this.entitySearchString = "";
        this.entityPage = 1;
        this.fetchAllEntityies();
      }
    },
    async updateAndLoadMore(value) {
      if (value == "LOADMORE") {
        this.entityLoadMore = true;
        this.entityPage++;
        this.payload.entity_id = "";
        this.fetchAllEntityies();
      } else if (value) {
        this.otherEntitiesLoading = true;
        await Promise.all([
          this.fetchEntityRelatedData(value),
          this.fetchBaseEntityFilters(value),
        ]);
        this.otherEntitiesLoading = false;
      }
    },
    async fetchBaseEntityFilters(value) {
      this.baseEntityFilters = [];
      let filters = await postAPICall("GET", "entity-filter", {
        entityId: value,
      });
      this.baseEntityFilters = [...filters.data];
    },
    async fetchEntityRelatedData(value) {
      await Promise.all([
        this.fetchEntityFieldRelatedEntities(value),
        this.fetchEntityRelationships(value),
        this.fetchEntityNestedRelationships(value),
      ]);
      if (this.getEntityFieldRelatedEntities) {
        this.otherEntities = [...this.getEntityFieldRelatedEntities];
        //remove existing fields
        this.selectedOtherEntities = this.selectedOtherEntities.filter((e) => {
          return (
            [
              ...this.otherEntities.flatMap((el) => el.key),
              ...this.relationshipsData.flatMap(
                (el) => el.relationship_id + "@relationship"
              ),
              ...this.nestedRelationshipsData.flatMap(
                (el) =>
                  el.relationship_id +
                  "@nested-" +
                  el.parent_relationship_details.relationship_id
              ),
            ].indexOf(e) !== -1
          );
        });
      }
    },
    async fetchEntityFieldRelatedEntities(value) {
      this.getEntityFieldRelatedEntities = null;
      let response = await postAPICall("GET", `/entities/related/${value}`);
      this.getEntityFieldRelatedEntities = response.data;
    },
    async getNecessaryInfo() {
      this.loading = true;
      await Promise.all([
        this.fetchAllCompanyTemplates(),
        this.getEntityViewDataById(this.entityViewDataId),
        this.fetchAllEntityies(),
        this.fetchEntityViews(),
        this.fetchAllFormTemplates(),
      ]);
      this.fieldsFromColumns = this.rulesFields();
      this.loading = false;
    },
    async fetchAllEmailTemplates() {
      if (this.payload?.send_attachment_to && this.payload?.printing_document) {
        let entityCol = this.getEntityTypeColoumns.find(
          (e) => e?.key == this.payload?.send_attachment_to
        );
        if (entityCol?.entity_id) {
          this.emailTemplatesLoading = true;
          let response = await postAPICall("GET", "email-template/list", {
            type: "ENTITY",
            entity_id: entityCol.entity_id,
          });
          if (response.data) {
            this.allEmailTemplates = response.data;
          }
          this.emailTemplatesLoading = false;
        }
      }
    },
    async fetchAllCompanyTemplates() {
      let response = await postAPICall("GET", "/document-templates", {
        get_all: true,
      });
      this.allDocumentTemplates = response?.data ? response?.data : [];
    },
    async fetchAllFormTemplates() {
      let getAllTemplates = await getAllCompanyTemplatesByPagination({
        get_all: true,
      });
      if (getAllTemplates?.data) {
        this.allFormTemplates = getAllTemplates?.data || [];
        this.allFormTemplates.forEach((t) => {
          this.templatesFieldsMap[t._id] = this.getTemplateFieldsInfo(t, true);
        });
      }
    },
    async fetchEntityViews() {
      this.allEntityViews = [];
      // await this.$store.dispatch("entityViews/getAllEntityViews", {
      //   get_all: true,
      // });
      const getAllEntityViews = await fetchEntityViews({
        get_all: true,
      });
      if (getAllEntityViews?.data) {
        this.allEntityViews = getAllEntityViews.data.filter(
          (e) => e._id !== this.entityViewDataId
        );
      }
    },
    async fetchAllEntityies(existedEntityIds = []) {
      this.entityOptionsLoading = true;
      let params = {
        populateData: true,
        page: this.entityPage,
        limit: this.entityLimit,
      };
      if (this.entitySearchString) {
        params["search_string"] = this.entitySearchString;
      }
      if (existedEntityIds && existedEntityIds.length) {
        params["existed_entity_ids"] = existedEntityIds;
      }
      let getAllEntities = await fetchEntitiesByPagination(params);
      // await this.$store.dispatch("entities/fetchEntities", params);
      if (this.isEntitySearching || !this.entityLoadMore) {
        this.allEntities = [];
      }
      if (getAllEntities) {
        this.hasMoreEntities = getAllEntities.has_more || false;
        this.allEntities = this.removeDuplicatesByProperty(
          [...this.allEntities, ...(getAllEntities.data || [])],
          "_id"
        );
        this.entityOptionsLoading = false;
      } else {
        this.entityOptionsLoading = false;
      }
      this.entityLoadMore = false;
    },
    removeDuplicatesByProperty(arr, prop) {
      const uniqueSet = new Set();
      const uniqueArray = arr.filter((obj) => {
        const propertyValue = obj[prop];
        if (!uniqueSet.has(propertyValue)) {
          uniqueSet.add(propertyValue);
          return true;
        }
        return false;
      });
      return uniqueArray;
    },
    async getEntityViewDataById(id) {
      if (id) {
        this.loading = true;
        this.loadingText = "Fetch existing data...";
        let entityView = await postAPICall(
          "GET",
          `entity-view-configuration/${id}`,
          {
            id: id,
            populateEntities: true,
          }
        );
        if (entityView?.data) {
          this.setEntityViewData(entityView.data);
        }
        this.loading = false;
      }
    },
    setEntityViewData(data) {
      this.payload.filter_open = data.filter_open || "Show";
      this.payload.filter_close = data.filter_close || "Hide";
      this.payload.add_serial_number = data.add_serial_number || false;
      this.payload.include_total = data.include_total || false;
      this.payload.include_filters_in_export =
        data.include_filters_in_export || false;
      this.payload.include_totals_in_export =
        data.include_totals_in_export || false;
      this.payload.name = data.name || "";
      this.payload.filter_fields = data.filter_fields || [];
      this.payload.main_columns = data.main_columns || [];
      this.payload.printing_document = data.printing_document || "";
      this.payload.printing_template = data.printing_template || "";
      this.payload.attachment_email_template =
        data.attachment_email_template || "";
      this.payload.send_attachment_to = data.send_attachment_to || "";
      this.payload.filter = data.filter || "";
      this.payload.aggregations = data.aggregations || [];
      this.payload.rules = data.rules || [];
      if (data.entity_id) {
        if (data.entity_id?._id) {
          this.allEntities.push(data.entity_id);
          this.allEntities = this.removeDuplicatesByProperty(
            [...this.allEntities],
            "_id"
          );
          this.payload.entity_id = data.entity_id._id;
        } else {
          this.payload.entity_id = data.entity_id;
        }
        this.fetchEntityRelatedData(this.payload.entity_id);
        this.fetchBaseEntityFilters(this.payload.entity_id);
      }
      if (data.other_entities) {
        let otherEntities = [];
        data.other_entities.forEach((e) => {
          if (e?.entity_id?._id) {
            otherEntities.push(e.entity_id);
            this.payload.other_entities.push({
              ...e,
              ...{ entity_id: e.entity_id._id },
            });
          } else {
            this.payload.other_entities.push(e);
          }
        });
        this.allEntities = this.removeDuplicatesByProperty(
          [...this.allEntities, ...otherEntities],
          "_id"
        );
        this.selectedOtherEntities = this.payload.other_entities.map((e) =>
          e.linked_field.includes("#")
            ? e.entity_id + "#" + e.linked_field
            : e.entity_id + "@" + e.linked_field
        );
      }
      this.fetchAllEmailTemplates();
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.view-navbar {
  display: flex !important;
  justify-content: space-between !important;
}
.input-text {
  width: 300px;
}
.filter-table {
  margin-top: 5px;
  table {
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #dddddd;
  }
}
.margin-top-10 {
  margin-top: 10px;
}
</style>

<style lang="scss" scoped>
.formula-group {
  margin-top: 0.5em;
  padding: 15px 0px;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}
</style>
<style lang="scss" scoped>
.formula-dialog {
  .form-left {
    min-height: 0px !important;
  }
  .variables-fields {
    display: flex !important;
    justify-content: space-between !important;
  }
  .operaters {
    text-align: center;
    margin-top: 1em;
    img {
      max-width: 25px;
    }
    .el-button-group {
      button {
        margin-bottom: 5px;

        @media (max-width: 600px) {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
  }
}
.formula-dialog .form-right .artboard .btn-variable {
  margin-top: 5px !important;
}
.currency:before {
  content: "*";
  color: red;
  position: absolute;
  margin-left: 107px;
  margin-top: 4px;
}
.formulaDateRequired::before {
  content: "*";
  color: red;
  position: absolute;
  margin-left: 120px;
  margin-top: 4px;
}
.formula-box {
  &.invalid {
    border-color: #ff1b48 !important;
    background-color: #ffecf0 !important;
  }
  &.valid {
    border-color: #0eac28 !important;
    background-color: #d6ffd6 !important;
  }
}
.artboard {
  flex-wrap: wrap !important;
}
.formula-group {
  .el-tag {
    font-size: 1em;
  }
}
.show-formula {
  margin-bottom: 1em;
  margin-top: 1em;
  h4 {
    margin-top: 0;
  }
  & > div {
    display: inline-flex;
    align-items: center;
    column-gap: 10px;
  }
  .status-label {
    border: 1px solid #efefef;
    border-radius: 10px;
    padding: 0 10px;
  }
}
.paragraphScroll {
  height: 450px;
  overflow-y: scroll;
  overflow-x: scroll;
  border-top: 1px ridge;
  border-left: 1px ridge;
  border-bottom: 1px ridge;
  margin-right: 5px;
  width: 100%;
}
.paragraphScroll::-webkit-scrollbar {
  width: 0.3em;
}
.paragraphScroll::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #e1e1e1;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}
i:hover {
  /* background-color: #dbf3fa; */
}
i:hover {
  color: rgb(114, 212, 230);

  transform: translate(-10%, -10%);
}
.formula-box {
  margin-top: 1em;
  padding: 0.5em 1em;
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 2px dashed #000577;
  min-height: 40px;
  font-size: 1.25em;
  font-weight: 300;
  font-style: italic;
  color: #000000;
}
.artboard {
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #d2d2d2;
  border-radius: 0px;
  padding: 0.25em;
  min-height: 60px;
  display: flex;
  align-items: center;
  overflow: scroll;
  width: 100%;
  background: #ffffff;
  .el-tag {
    margin-left: 0.5em;
  }
  .btn-variable {
    background-color: #000577;
    border-radius: 30px;
    color: #ffffff;
    border: #000577;
    padding: 10px 35px 10px 15px;
    position: relative;
    &.active {
      background-color: #dd5050 !important;
      box-shadow: 0 0 0 4px #efefef;
    }
    &:hover {
      box-shadow: 0 0 0 4px #f7f7f7;
    }
    img {
      position: absolute;
      top: 7px;
      right: 7px;
      width: 20px;
      height: 20px;
      opacity: 0.7;
    }
    &:hover {
      img {
        opacity: 1;
      }
    }
  }
  .btn-operation {
    display: flex;
    align-items: center;
    margin: 0 5px;
    padding: 5px 6px;
    img {
      max-width: 15px;
    }
    &.active {
      border: 2px solid #ff3e3e !important;
      background-color: #f34423 !important;
    }
  }
  .text-button {
    font-size: 4em;
    line-height: 15px;
    span {
      position: relative;
      margin-top: -9px;
      display: block;
      color: #c4c4c4;
    }
  }
  .el-button + .el-button {
    margin: 0 5px;
  }
}
</style>
